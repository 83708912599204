import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { SliderItemType, SliderItmesType } from '../../types/General';
import { Link } from 'react-router-dom';

export default function Slider(
    {
        items = [],
        url = '/prona/',
        btn = 'Shiko pronen',
    }
        :
        {
            items?: SliderItmesType,
            isFetching?: boolean,
            url?: string,
            btn?: string
        }
) {
    return (
        <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
                delay: 3000000,
                disableOnInteraction: false,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mainSwiper rounded-b-2xl"
        >
            {items.map((item: SliderItemType) => (
                <SwiperSlide key={item.id}>
                    <img style={{ width: "100%", height: "80vh", objectFit: "cover" }} src={item.imageUrl} alt="" />
                    <div className="absolute inset-0 bg-black opacity-50" style={{ height: "80vh" }}></div>
                    <div className='swiper-text absolute inset-0 max-w-screen-2xl mx-auto'>
                        <div className="h-full w-4/5 md:w-1/2 flex items-center">
                            <div>
                                <h1 className='text-5xl lg:text-6xl text-white ui-serif px-2 2xl:px-0'>
                                    {item.name}
                                </h1>
                                <p className='text-white px-2 2xl:px-0'>{item.description}</p>
                                <div className="flex items-center pt-8 px-2 2xl:px-0">
                                    <div className="flex-shrink-0">
                                        <Link to={url + item.id + ""} className="relative inline-flex items-center gap-x-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-red-700 hover:text-white"
                                        >{btn}</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </SwiperSlide>
            ))}
        </Swiper>
    )
}
