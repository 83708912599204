import React, { useEffect, useState } from 'react';

interface FileUploadProps {
  onFilesChange: (files: any[], filesWithPriority: any[]) => void;
  singleFile?: boolean;
  cols?: number;
  preview?: boolean;
  validations?: any;
  clearFiles?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = (
  {
    onFilesChange, singleFile = false, cols = 3, preview = true, validations, clearFiles
  }) => {
  const [files, setFiles] = useState<any[]>([]);
  const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFiles = Array.from(event.dataTransfer.files);

    // If singleFile is true, take only the first file from the dropped files
    const filesToUpload = singleFile ? [newFiles[0]] : newFiles;

    updateFiles(filesToUpload);
  };

  const onRemoveFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    onFilesChange(updatedFiles, updatedFiles.map((file, index) => ({ ...file, priority: index + 1 })));
  };

  const onDragStart = (index: number) => {
    setDraggedIndex(index);
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    if (draggedIndex === null || draggedIndex === index) return;

    const updatedFiles = [...files];
    const draggedFile = updatedFiles[draggedIndex];

    updatedFiles.splice(draggedIndex, 1);
    updatedFiles.splice(index, 0, draggedFile);

    setFiles(updatedFiles);
    setDraggedIndex(index);
  };

  const onDragEnd = () => {
    setDraggedIndex(null);
    // Update parent component with new order and priority
    onFilesChange(
      files,
      files.map((file, index) => ({ ...file, priority: index + 1 }))
    );
  };

  const updateFiles = (newFiles: any[]) => {
    const updatedFiles = [...files, ...newFiles];
    setFiles(updatedFiles);
    onFilesChange(updatedFiles, updatedFiles.map((file, index) => ({ ...file, priority: index + 1 })));
  };

  useEffect(() => {
    setFiles([]);
  }, [clearFiles]);

  return (
    <div>
      <div
        className="border-dashed border-2 p-4 cursor-pointer"
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <input
          type="file"
          multiple={!singleFile}
          onChange={(e) => {
            if (e.target.files) {
              const newFiles = Array.from(e.target.files);
              updateFiles(newFiles);
            }
          }}
          style={{ display: 'none' }}
        />
        <p
          className="text-gray-500"
          onClick={() =>
            ((document.querySelector('input[type="file"]') as unknown) as HTMLInputElement)?.click()
          }
        >
          Kliko ose hidh foto ketu
        </p>
      </div>
      <div className="grid grid-cols-3">
        <div>

        </div>
      </div>
      {files.length > 0 && preview && (
        <div className="mt-4">
          <ul className={`grid grid-cols-${cols} gap-4`}>
            {files.map((file, index) => (
              <li
                key={index}
                className="relative"
                draggable
                onDragStart={() => onDragStart(index)}
                onDragOver={(e: any) => onDragOver(e, index)}
                onDragEnd={onDragEnd}
              >
                <div className="border border-gray-200 p-2">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className="mb-2 h-40 object-cover w-full aspect-video"
                  />
                  <span className="text-gray-600">{file.name}</span>
                  <button
                    onClick={() => onRemoveFile(index)}
                    className="absolute top-0 right-0 px-2 py-1 text-sm bg-neutral-800 text-white rounded-full"
                  >
                    X
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {validations && validations['image'] && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {validations['image']}
        </p>
      )}
    </div>
  );
};

export default FileUpload;
