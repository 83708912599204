import { Fragment, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from '../../store/main/MainSlice';

export default function NotificationAlert() {
    const dispatch = useDispatch();

    const notification = useSelector((state: any) => state.main.notification); 

    const [show, setShow] = useState(notification.visible);

    const handleHide = async () => {
        setShow(false);
        dispatch(showNotification({ visible: false }));
    };

    useEffect(() => {
        setShow(notification.visible);
        if (notification.visible) {
            const timeoutId = setTimeout(() => {
                setShow(false);
                dispatch(showNotification({ visible: false }));
            }, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [notification.visible]);


    return (
        <div
            style={{ zIndex: 100 }}
            aria-live="assertive"
            className="pointer-events-none fixed z-50 inset-0 flex items-start"
        >
            <div className="flex w-full flex-col items-center sm:items-end">
                <Transition
                    show={show}
                    as={Fragment}
                    enter="transform ease-out duration-300 transition"
                    enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="pointer-events-auto w-full sm:max-w-sm overflow-hidden bg-neutral-800 shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4">
                            <div className="flex items-center">
                                <div className="flex w-0 flex-1 justify-between">
                                    <p className="w-0 flex-1 text-sm font-medium text-white">{notification.msg}</p>
                                </div>
                                <div className="ml-4 flex flex-shrink-0">
                                    <button
                                        type="button"
                                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                                        onClick={() => {
                                            handleHide()
                                        }}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    )
}