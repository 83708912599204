import React from "react";
import AccountSidebar from "../AccountSidebar";
import NotificationAlert from "../../../general/NotificationAlert";
import { LayoutSelectorProps, NavigationItem } from "../../../types/General";

import {
  Cog8ToothIcon,
  DocumentDuplicateIcon,
  PresentationChartLineIcon,
  Square2StackIcon,
  WalletIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

const FrontLayout: React.FC<LayoutSelectorProps> = ({ children }) => {
  //#region costants
  const location = useLocation();
  const navigation: NavigationItem[] = [
    {
      name: "Modulet",
      href: "/account/main/dashboard",
      icon: Square2StackIcon,
      current: location.pathname === "/account/main/dashboard",
    },
    {
      name: "Profili kryesor",
      href: "/account/main/profile",
      icon: Cog8ToothIcon,
      current: location.pathname === "/account/main/profile",
    },
    {
      name: "Documents",
      href: "/account/main/documents",
      icon: DocumentDuplicateIcon,
      current: location.pathname === "/account/main/documents",
    },
    {
      name: "Transaksione",
      href: "/account/main/transactions",
      icon: WalletIcon,
      current: location.pathname === "/account/main/transactions",
    },
    {
      name: "Raporte",
      href: "/account/main/reports",
      icon: PresentationChartLineIcon,
      current: location.pathname === "/account/main/reports",
    },
  ];
  //#endregion

  return (
    <div>
      <NotificationAlert />
      <AccountSidebar navigation={navigation} />
      <main className="pt-10 lg:pl-72 pt-20">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
};

export default FrontLayout;
