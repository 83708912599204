import { useState } from 'react'
import { useFetchCarListQuery } from '../../../store/cars/CarsStore';
import Pagination from '../../../components/general/Pagination';
import PropertyModalFilters from '../../../components/front/filters/PropertyModalFilters';
import PropertyMainCard from '../../../components/front/cards/PropertyMainCard';
import { getUrlParams, scrollToDiv } from '../../../services/helpers';
import Loading from '../../../components/general/Loading';
import CarMainCard from '../../../components/front/cards/CarMainCard';


export default function CarList() {
    const params = getUrlParams();

    //#region rtk
    const { data, isFetching } = useFetchCarListQuery(params);
    //#endregion

    //#region states
    const [page, setPage] = useState(params)
    //#endregion

    if (isFetching && !data?.meta) {
        return <Loading />;
    }

    //#region functions
    const handlePageClick = async (page: number) => {
        const params = new URLSearchParams(window.location.search);
        params.set('page', page.toString());
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
        setPage(page);
        scrollToDiv('properties', 80);
    };

    const onFiltersChange = async (data: any) => {
        const params = new URLSearchParams(data);
        if (params.has('page') && params.get('page') !== '1') {
            params.set('page', '1');
        }
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
        setPage(params)
    };
    //#endregion

    console.log(data)

    return (
        <>
            <div id='properties'>
                <PropertyModalFilters onFiltersChange={onFiltersChange} />
            </div>
            <div>
                <div className="mx-auto max-w-screen-2xl px-2 py-5 2xl:px-0 sm:py-5">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 full:grid-cols-4 gap-y-4 gap-x-3">
                        {data?.data.map((car: any) => (
                            <CarMainCard key={car.id} car={car} />
                        ))}
                    </div>
                    <Pagination
                        currentPage={data?.current_page ?? 1}
                        from={data?.from ?? 0}
                        to={data?.to ?? 0}
                        total={data?.total ?? 0}
                        last={data?.last_page ?? 0}
                        onPageClick={handlePageClick}
                    />
                </div>
            </div>
        </>
    )
}
