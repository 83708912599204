import EFInput from '../../../components/general/EFInput'
import { useEffect, useState } from 'react';
import { useFetchAgentQuery, useSaveAgentMutation } from '../../../store/account/properties/accountPropertiesStore';
import Loading from '../../../components/general/Loading';
import { useDispatch } from 'react-redux';
import { broadcrumb, showNotification } from '../../../store/main/MainSlice';
import FileUpload from '../../../components/general/FileUpload';
import { Cities } from '../../../services/constants';
import SingleSelect from '../../../components/general/SingleSelect';

const pages = [
  { name: 'Prona', href: '/account/properties/dashboard', current: false },
  { name: 'Agjent', href: '/account/properties/agent/profile', current: false },
  { name: 'Profili', href: '#', current: true },
]

export default function AgentProfile() {

  //#region rtk
  const { data, isFetching } = useFetchAgentQuery({});
  const [saveAgent] = useSaveAgentMutation();
  //#endregion

  //#region costants
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>();
  //#endregion

  const handleChange = (e: any, key: string) => {
    let value = e;
    if (key == 'city') {
      value = e;
    } else {
      value = e.target.value;
    }
    setFormData({
      ...formData,
      [key]: value,
    });
  };


  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const response: any = await saveAgent(formData);
    if (response.data?.status) {
      dispatch(showNotification({ msg: "U perditesua me suksess!", visible: true }));
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };

  const handleFilesChange = async (e: any) => {

    var img = URL.createObjectURL(e[0]);

    setFormData({
      ...formData,
      ['profile_img']: e[0],
      ['image']: img,
    });
  };

  useEffect(() => {
    if (!isFetching && data?.data) {
      const filteredData = Object.fromEntries(
        Object.entries(data.data).filter(([key, value]) => value !== null && value !== undefined)
      );
      setFormData(filteredData);
    }
  }, [isFetching]);

  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, []);

  if (isFetching) {
    return <Loading />;
  }


  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 sm:col-span-3">
          <img className='w-full' src={formData?.image} alt="" />
          <div className="my-2">
            <FileUpload onFilesChange={(e) => handleFilesChange(e)} singleFile={true} cols={1} preview={false} />
          </div>
          <p><b>Kodi juaj: </b>{formData.code}</p>
        </div>
        <div className="col-span-12 sm:col-span-9">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <EFInput onChange={handleChange} label='Emri i agjentit' required={true} defaultValue={formData?.name} name='name' validations={errors} />
            <EFInput onChange={handleChange} label='Email' required={true} defaultValue={formData?.email} name='email' validations={errors} hasIcon={true} />
            <EFInput onChange={handleChange} label='Telefon' required={true} defaultValue={formData?.phone} name='phone' validations={errors} />
            <EFInput onChange={handleChange} label='Website' defaultValue={formData?.website} name='website' validations={errors} hasIcon={true} />
            <EFInput onChange={handleChange} label='Facebook' defaultValue={formData?.facebook} name='facebook' validations={errors} />
            <EFInput onChange={handleChange} label='Instagram' defaultValue={formData?.instagram} name='instagram' validations={errors} />
            <EFInput onChange={handleChange} label='Twitter' defaultValue={formData?.twitter} name='twitter' validations={errors} />
            <EFInput onChange={handleChange} label='Tiktok' defaultValue={formData?.tiktok} name='tiktok' validations={errors} />
            <EFInput onChange={handleChange} label='Adresa' required={true} defaultValue={formData?.address} name='address' validations={errors} />
            <div className='-mt-2'>
              <SingleSelect className="mt-2" name='city' required={true} onSelectChange={(e: any) => handleChange(e, 'city')} defaultValue={formData?.city ?? -1} items={Cities} isFetching={isFetching} validations={errors} label="Zgjidh qytetin" />
            </div>
          </div>
          <div className="col-span-full">
            <div className="mt-2">
              <textarea
                name="about"
                rows={3}
                onChange={(e) => handleChange(e, 'desc')}
                className="block p-2 w-full rounded-md border py-1.5 text-gray-900 focus:outline-none focus:border-gray-500 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                defaultValue={formData?.desc}
                placeholder='Pershkrimi i kompanise'
              />
            </div>
          </div>
          <button
            type="button"
            className="mt-2 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
            onClick={handleSubmit}
          >
            Ruaj te dhenat
          </button>
        </div>
      </div>
    </>

  )
}
