import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
    Cities,
    PropertyBathrooms,
    PropertyDocuments,
    PropertyFurnitures,
    PropertyRooms,
    PropertyStatuses,
    PropertyTypes,
} from "../../../services/constants";
import SingleSelect from "../../general/SingleSelect";
import EFInput from "../../general/EFInput";
import EFRadioGroup from "../../general/EFRadioGroup";
import { Zones } from "../../../../src/services/zones";
import {
    findInObjects,
    getUrlParams,
    isNotNullOrUndefined,
} from "../../../../src/services/helpers";
import SelectBox from "../../general/SelectBox";

export default function PropertyAccountFilters({
    onFiltersChange,
    open,
    setOpenFilters,
}: {
    onFiltersChange: any;
    open: boolean;
    setOpenFilters: any;
}) {
    const params = getUrlParams();
    const [formData, setFormData] = useState<any>(params);
    const [cityZones, setCityZones] = useState<any>();

    const handleChange = (e: any, key: string) => {
        let value = e;
        if (key === "fjaleKyce" || key === "minPrice" || key === "maxPrice")
            value = e.target.value;
        if (key === "city") setCityZones(Zones.filter((x) => x.city_id === value));
        setFormData({
            ...formData,
            [key]: value,
        });
    };

    //Clear filters by key from state and refilter data
    const clear = (keyToRemove: string) => {
        const updatedFormData = { ...formData };
        delete updatedFormData[keyToRemove];
        if (keyToRemove === "city") delete updatedFormData["zone"];
        setFormData(updatedFormData);
        onFiltersChange(updatedFormData);
    };

    const submitdata = () => {
        onFiltersChange(formData);
        setOpenFilters(false);
    };
    const clearFilters = () => {
        onFiltersChange({});
        setOpenFilters(false);
        setFormData({});
    };
    return (
        <>
            {Object.keys(params).length > (params.page ? 1 : 0) &&
                <div className="flex mx-auto max-w-screen-2xl px-2 2xl:px-0">
                    <div className="overflow-x-auto flex ml-2">
                        {params.status && (
                            <button
                                type="button"
                                className="relative border border-zinc-300 dark:border-zinc-600 dark:bg-white text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                                onClick={() => clear("status")}
                            >
                                <span className="mt-0.5 capitalize min-w-max">
                                    {parseInt(params.status) === 1 ? "Prona ne shitje" : "Prona me qera"}
                                </span>
                                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
                            </button>
                        )}
                        {params.fjaleKyce && (
                            <button
                                type="button"
                                className="relative border border-zinc-300 dark:border-zinc-600 dark:bg-white text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                                onClick={() => clear("fjaleKyce")}
                            >
                                <span className="mt-0.5 capitalize min-w-max">
                                    {params.fjaleKyce}
                                </span>
                                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
                            </button>
                        )}
                        {params.type && (
                            <button
                                type="button"
                                className="relative border border-zinc-300 dark:border-zinc-600 dark:bg-white text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                                onClick={() => clear("type")}
                            >
                                <span className="mt-0.5 capitalize min-w-max">
                                    {findInObjects(
                                        PropertyTypes,
                                        "id",
                                        "name",
                                        parseInt(params.type)
                                    )}
                                </span>
                                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
                            </button>
                        )}
                        {params.rooms && (
                            <button
                                type="button"
                                className="relative border border-zinc-300 dark:border-zinc-600 dark:bg-white text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                                onClick={() => clear("rooms")}
                            >
                                <span className="mt-0.5 capitalize min-w-max">
                                    {findInObjects(
                                        PropertyRooms,
                                        "id",
                                        "name",
                                        parseInt(params.rooms)
                                    )}
                                </span>
                                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
                            </button>
                        )}
                        {params.bathrooms && (
                            <button
                                type="button"
                                className="relative border border-zinc-300 dark:border-zinc-600 dark:bg-white text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                                onClick={() => clear("bathrooms")}
                            >
                                <span className="mt-0.5 capitalize min-w-max">
                                    tualete:
                                    {findInObjects(
                                        PropertyBathrooms,
                                        "id",
                                        "name",
                                        parseInt(params.bathrooms)
                                    )}{" "}
                                </span>
                                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
                            </button>
                        )}
                        {params.city && (
                            <button
                                type="button"
                                className="relative border border-zinc-300 dark:border-zinc-600 dark:bg-white text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                                onClick={() => clear("city")}
                            >
                                <span className="mt-0.5 capitalize min-w-max">
                                    {findInObjects(Cities, "id", "name", parseInt(params.city))}
                                </span>
                                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
                            </button>
                        )}
                        {params.zone && (
                            <button
                                type="button"
                                className="relative border border-zinc-300 dark:border-zinc-600 dark:bg-white text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                                onClick={() => clear("zone")}
                            >
                                <span className="mt-0.5 capitalize min-w-max">
                                    {findInObjects(Zones, "id", "name", parseInt(params.zone))}
                                </span>
                                <XMarkIcon className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm" />
                            </button>
                        )}
                        {Object.keys(params).length > (params.page ? 1 : 0) && (
                            <button
                                type="button"
                                className="relative border border-zinc-300 dark:border-zinc-600 dark:bg-white text-xs rounded-md flex px-2 py-1 h-7 ml-1 bg-white"
                            >
                                <span className="mt-0.5 capitalize min-w-max">Te gjitha</span>
                                <XMarkIcon
                                    className="h-4 w-4 -mt-1 -mr-2 ml-1 bg-neutral-700 text-white rounded-sm"
                                    onClick={() => clearFilters()}
                                />
                            </button>
                        )}
                    </div>
                </div>
            }
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={() => setOpenFilters(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <div className="fixed inset-0" />
                    <div className="fixed inset-0 overflow-hidden">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-x-full"
                                    enterTo="translate-x-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-x-0"
                                    leaveTo="translate-x-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-screen max-w-xl ">
                                        <form className="flex h-full flex-col divide-y divide-gray-200 bg-white dark:bg-neutral-800 shadow-xl">
                                            <div className="px-4 py-4 sm:px-6 bg-zinc-200 w-full">
                                                <div className="flex items-center justify-between">
                                                    <Dialog.Title className="font-semibold leading-6">
                                                        Filtro pronat
                                                    </Dialog.Title>
                                                    <div className="ml-3 flex h-7 items-center">
                                                        <button
                                                            type="button"
                                                            className="relative rounded-md"
                                                            onClick={() => setOpenFilters(false)}
                                                        >
                                                            <span className="absolute -inset-2.5" />
                                                            <span className="sr-only">Close panel</span>
                                                            <XMarkIcon
                                                                className="h-6 w-6"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="h-0 flex-1 overflow-y-auto pb-8  pt-2">
                                                <div className="flex flex-1 flex-col justify-between">
                                                    <div className="px-4 sm:px-6">
                                                        <EFRadioGroup
                                                            items={PropertyStatuses}
                                                            onChange={handleChange}
                                                            name="status"
                                                            selectedItem={formData.status}
                                                            smCols={2}
                                                        />

                                                        <EFInput
                                                            className="mt-2"
                                                            onChange={handleChange}
                                                            label="Fjalekyce"
                                                            defaultValue={formData?.fjaleKyce}
                                                            name="fjaleKyce"
                                                        />
                                                        <SelectBox
                                                            className="mt-2"
                                                            onSelectChange={(e: any) =>
                                                                handleChange(e, "type")
                                                            }
                                                            defaultValue={parseInt(formData?.type)}
                                                            items={PropertyTypes}
                                                            label="Lloji i prones"
                                                        />

                                                        <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">
                                                            Cmimi
                                                        </label>
                                                        <div className="grid grid-cols-2 gap-2">
                                                            <EFInput
                                                                onChange={handleChange}
                                                                type="number"
                                                                defaultValue={formData?.minPrice}
                                                                name="minPrice"
                                                                placeholder="Cmimi minimum"
                                                            />
                                                            <EFInput
                                                                onChange={handleChange}
                                                                type="number"
                                                                label=""
                                                                defaultValue={formData?.maxPrice}
                                                                name="maxPrice"
                                                                placeholder="Cmimi maksimum"
                                                            />
                                                        </div>

                                                        <SingleSelect
                                                            className="mt-2"
                                                            onSelectChange={(e: any) =>
                                                                handleChange(e, "city")
                                                            }
                                                            defaultValue={parseInt(formData?.city)}
                                                            items={Cities}
                                                            label="Zgjidh qytetin"
                                                        />

                                                        {isNotNullOrUndefined(formData) &&
                                                            formData.city && (
                                                                <SelectBox
                                                                    className="mt-2"
                                                                    onSelectChange={(e: any) =>
                                                                        handleChange(e, "zone")
                                                                    }
                                                                    defaultValue={parseInt(formData?.zone)}
                                                                    items={cityZones}
                                                                    label="Zgjidh zonen"
                                                                />
                                                            )}

                                                        {(parseInt(formData.type) === 1 || parseInt(formData.type) === 4) && (
                                                            <EFRadioGroup
                                                                items={PropertyRooms}
                                                                onChange={handleChange}
                                                                label="Dhoma"
                                                                name="rooms"
                                                                selectedItem={formData.rooms}
                                                            />
                                                        )}

                                                        {(parseInt(formData.type) === 1 || parseInt(formData.type) === 4) && (
                                                            <EFRadioGroup
                                                                items={PropertyBathrooms}
                                                                onChange={handleChange}
                                                                label="Tualete"
                                                                name="bathrooms"
                                                                selectedItem={formData.bathrooms}
                                                            />
                                                        )}

                                                        {(parseInt(formData.type) === 1 || parseInt(formData.type) === 4) && (
                                                            <EFRadioGroup
                                                                items={PropertyFurnitures}
                                                                onChange={handleChange}
                                                                label="Mobilimi"
                                                                name="furniture"
                                                                selectedItem={formData.furniture}
                                                            />
                                                        )}
                                                        <EFRadioGroup
                                                            items={PropertyDocuments}
                                                            onChange={handleChange}
                                                            label="Dokumentacioni"
                                                            name="documents"
                                                            selectedItem={formData.documents}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-shrink-0 justify-end px-4 py-3 bg-zinc-200 dark:bg-zinc-900">
                                                <button
                                                    type="button"
                                                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                                    onClick={() => setOpenFilters(false)}
                                                >
                                                    Mbyll
                                                </button>
                                                <button
                                                    type="button"
                                                    className="ml-4 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-700"
                                                    onClick={() => submitdata()}
                                                >
                                                    Filtro pronat
                                                </button>
                                            </div>
                                        </form>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
}
