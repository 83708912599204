import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useImperativeHandle, useState } from 'react';
import EFInput from '../EFInput';
import CkEdit from '../CkEdit';
import { useDispatch, useSelector } from 'react-redux';
import { useContactSellerMutation } from '../../../store/main/MainStore';
import { showNotification } from '../../../store/main/MainSlice';

const defaultContact = {
    user_id: null,
    module: '',
    visible: false,
    width: 'sm:max-w-xl'
}

export default function PropertyContactModal(props: any) {

    //#region constants
    const { description } = props;
    const dispatch = useDispatch();
    const generalData = useSelector((state: any) => state.main.generalData);
    //#endregion

    //#region RTK
    const [contactSeller] = useContactSellerMutation();
    //#endregion

    //#region states
    const [formData, setFormData] = useState<any>({
        description: description
    });
    const [contact, setContact] = useState<any>(defaultContact);
    const [errors, setErrors] = useState<any>();
    //#endregion

    //#region functions
    useImperativeHandle(props.forwardedRef, () => ({
        handleContactPopup(user_id: number, module: string,) {
            setContact({ ...contact, visible: true, user_id: user_id, module: module });
        }
    }));

    const handleChangeEditor = (event: any, editor: any) => {
        setFormData({
            ...formData,
            ['description']: editor.getData(),
        });
    };

    const handleChange = (e: any, key: string) => {
        setFormData({
            ...formData,
            [key]: e.target.value,
        });
    };

    const sendMessage = async () => {
        if (generalData.name && !formData.name) {
            formData.name = generalData.name;
        }
        if (generalData.email && !formData.email) {
            formData.email = generalData.email;
        }
        if (generalData.phone && !formData.phone) {
            formData.phone = generalData.phone;
        }

        formData.user_id = contact.user_id;
        formData.module = contact.module;

        const response: any = await contactSeller(formData);
        if (response.error) {
            return setErrors(response.error.data.errors);
        } else {
            setErrors(null);
        }

        if (response?.data?.status) {
            dispatch(showNotification({ msg: response.data.message, visible: true }));
        }

        setContact(defaultContact);
    };
    //#endregion

    return (
        <Transition show={contact.visible} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                onClose={() => setContact(defaultContact)}
            >
                <Transition
                    show={contact.visible}
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition
                            show={contact.visible}
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className='bg-white relative w-full sm:max-w-xl text-left rounded-lg'>
                                <div className='bg-neutral-800 px-2 py-3 rounded-t-lg flex justify-between'>
                                    <h3 className='text-white'>Kontakto</h3>
                                    <button type="button" className="rounded-md bg-neutral-800 px-1 text-sm font-semibold text-white shadow-sm sm:w-auto" onClick={() => setContact(defaultContact)}>
                                        <XMarkIcon className="text-white h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="p-5">
                                    <div className="grid grid-cols-2 gap-3">
                                        <EFInput onChange={handleChange} label='Emri i plote' defaultValue={generalData?.name} name='name' className='w-full' validations={errors} />
                                        <EFInput onChange={handleChange} label='Telefon' defaultValue={generalData?.phone} name='phone' className='w-full' validations={errors} />
                                    </div>
                                    <EFInput onChange={handleChange} label='Email' name='email' defaultValue={generalData?.email} type='email' className='w-full mt-2' validations={errors} />
                                    <CkEdit onChange={handleChangeEditor} label='Mesazhi juaj' name='description' className="mt-2" defaultValue={formData.description} validations={errors} />
                                </div>
                                <div className="mt-5 px-3 mb-3 sm:mt-4 sm:flex">
                                    <button type="button" className="inline-flex w-full justify-center rounded-md bg-neutral-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-neutral-700 sm:w-auto"
                                        onClick={sendMessage}
                                    >
                                        Kontakto
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm border hover:bg-red-800 sm:ml-3 sm:mt-0 sm:w-auto"
                                        onClick={() => setContact(defaultContact)}
                                    >
                                        Anullo
                                    </button>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
