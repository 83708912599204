import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeader from "../../../src/services/apiHeaders";


// API endpoint for fetching data
const url = process.env.REACT_APP_API_BASE_URL;


export const authApi = createApi({
    reducerPath: "auth",
    baseQuery: fetchBaseQuery({
        baseUrl: url,
        fetchFn: async (...args) => {
            return fetch(...args);
        },
        prepareHeaders(headers) {
            return getHeader(headers);
        },
    }),

    endpoints: (builder) => ({
        login: builder.mutation({
            query: (body) => ({
                url: '/login',
                method: 'POST',
                body,
            }),
        }),
        register: builder.mutation({
            query: (body) => ({
                url: '/register',
                method: 'POST',
                body,
            }),
        }),
        updateProfile: builder.mutation({
            query: (body) => ({
                url: '/update-profile',
                method: 'POST',
                body,
            }),
        }),
        changePassword: builder.mutation({
            query: (body) => ({
                url: '/update-password',
                method: 'POST',
                body,
            }),
        }),
        logout: builder.mutation({
            query: (body) => ({
                url: '/logout',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useLoginMutation, useRegisterMutation, useUpdateProfileMutation, useChangePasswordMutation, useLogoutMutation } = authApi;
