import { useState, useLayoutEffect } from 'react';

// Custom hook starts with `use`
export function useCalculateHeight(pixels: number) {

    // Initialize state with the initial value
    const [height, setHeight] = useState(window.innerHeight - pixels);

    useLayoutEffect(() => {
        // Define a function to update the state based on the current window.innerHeight
        function updateHeight() {
            setHeight(window.innerHeight - pixels);
        }

        // Add the event listener for window resize
        window.addEventListener('resize', updateHeight);

        // Cleanup: Remove the event listener when the component unmounts or rerenders
        return () => window.removeEventListener('resize', updateHeight);
    }, [pixels]); // Dependency array ensures effect runs only if 'pixels' changes

    return height;
}
