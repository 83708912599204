import { useState } from "react";
import { useLoginMutation } from "../../store/auth/AuthStore";
import { setCookie } from "../../services/helpers";
import EFInput from "../../components/general/EFInput";
import { showNotification } from "../../store/main/MainSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  //#region costants
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region rtk
  const [login, loginResult] = useLoginMutation();
  //#endregion

  //#region states
  const [errors, setErrors] = useState<any>();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  //#endregion

  //#region functions
  const handleChange = (e: any, key: string) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const result: any = await login(formData);
    if (result.data?.status === 200) {
      setCookie("authToken", result.data.token);
      window.location.href = "account/main/dashboard";
    }
    if (result.data?.status === 401) {
      dispatch(showNotification({ msg: result.data?.message, visible: true }));
    }
    if (result.error) {
      setErrors(result.error.data.errors);
    } else {
      setErrors(null);
    }
  };
  //#endregion

  return (
    <>
      <div className="flex min-h-full flex-1 bg-white mt-14 dark:bg-zinc-900 dark:text-white">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
                Identifikohni ne llogarine tuaj
              </h2>
            </div>

            <div className="mt-10">
              <div>
                <form
                  onSubmit={handleSubmit}
                  action="#"
                  method="POST"
                  className="space-y-6"
                >
                  <EFInput
                    onChange={handleChange}
                    label="Email"
                    required={true}
                    defaultValue={formData?.email}
                    name="email"
                    validations={errors}
                    hasIcon={true}
                  />
                  <EFInput
                    onChange={handleChange}
                    label="Fjalekalimi"
                    required={true}
                    defaultValue={formData?.password}
                    name="password"
                    validations={errors}
                    hasIcon={true}
                    type="password"
                  />
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-zinc-800 focus:ring-zinc-800 dark:text-white"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-3 block text-sm leading-6 text-gray-700 dark:text-white"
                      >
                        Me mbaj mend
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <span className="font-semibold text-zinc-800 hover:text-zinc-600 dark:text-white">
                        Harruat fjalekalimin?
                      </span>
                    </div>
                  </div>
                  <div className="text-sm leading-6">
                    Nuk keni ende nje llogari?
                    <span
                      onClick={() => navigate("/register")}
                      className="font-semibold hover:text-red-600 ml-1 cursor-pointer text-red-700"
                    >
                      Regjistrohu
                    </span>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={loginResult.isLoading}
                      className="flex w-full justify-center rounded-md bg-neutral-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-zinc-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-800"
                    >
                      {loginResult.isLoading ? "Ne proces ..." : "Identifikohu"}
                    </button>
                  </div>
                </form>
              </div>

              <div className="mt-10">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900 dark:bg-zinc-900 dark:text-white">
                      Ose identifikohuni nepermjet
                    </span>
                  </div>
                </div>
                <div className="mt-6 grid grid-cols-2 gap-4">
                  <span className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]">
                    <svg
                      fill="#fff"
                      className="h-5 w-5"
                      version="1.1"
                      id="Layer_1"
                      viewBox="0 0 310 310"
                    >
                      <g id="XMLID_834_">
                        <path
                          id="XMLID_835_"
                          d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
                                                    c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
                                                    V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
                                                    C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
                                                    c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"
                        />
                      </g>
                    </svg>

                    <span className="text-sm font-semibold leading-6">
                      Facebook
                    </span>
                  </span>
                  <span className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]">
                    <svg
                      fill="#ffffff"
                      className="h-5 w-5"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 210 210"
                      stroke="#ffffff"
                    >
                      <g id="SVGRepo_bgCarrier" />
                      <g id="SVGRepo_tracerCarrier" />
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40 c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105 S0,162.897,0,105z" />{" "}
                      </g>
                    </svg>
                    <span className="text-sm font-semibold leading-6">
                      Google
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0  flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/demo/login.jpg"
            alt=""
          />
        </div>
      </div>
    </>
  );
}
