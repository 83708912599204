// src/App.tsx
import React from 'react';
import Routes from './routes/Routes';
import { store } from './store/store'
import { Provider } from 'react-redux';

const App: React.FC = () => {
  return (
    <div className="App dark:text-zinc-400">
      <Provider store={store}>
        <Routes />
      </Provider>
    </div>
  );
};

export default App;
