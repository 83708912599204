import Slider from '../../../components/front/slider/Slider'
import { Link } from 'react-router-dom'
import PropertiesCarousel from '../../../components/front/carousel/PropertiesCarousel'
import BigHorizontalBanner from '../../../components/front/cards/BigHorizontalBanner'
import { useFetchMainQuery } from '../../../store/main/MainStore'
import { SliderItmesType } from '../../../components/types/General'

export default function Home() {
    const mySliderList: SliderItmesType = [
        {
            id: 1,
            name: "Shitet apartament 2+1 ne Tirane",
            description: "Shitet apartament me hipoteke ne rrugen Mine Peza.",
            url: '/slider-1',
            hasButtons: true,
            imageUrl: '/demo/property_img_6.jpg',
        },
        {
            id: 2,
            name: "Shitet apartament 2+1 ne Durres",
            description: "Shitet apartament me hipoteke ne durres ne zonene plazhit.",
            url: '/slider-2',
            hasButtons: false,
            imageUrl: '/demo/property_img_1.jpg',
        },
        {
            id: 3,
            name: "Shitet apartament 2+1 ne Tirane",
            description: "Shitet apartament me hipoteke ne rrugen Mine Peza.",
            url: '/slider-1',
            hasButtons: true,
            imageUrl: '/demo/property_img_6.jpg',
        },
        {
            id: 4,
            name: "Shitet apartament 2+1 ne Durres",
            description: "Shitet apartament me hipoteke ne durres ne zonene plazhit.",
            url: '/slider-2',
            hasButtons: false,
            imageUrl: '/demo/property_img_1.jpg',
        },
    ];
    //#region rtk
    const { data, isFetching } = useFetchMainQuery({});
    //#endregion
    return (
        <>
            <Slider items={mySliderList} />
            <div className="max-w-screen-2xl mx-auto my-5 mx-2 lg:mx-auto px-2 dark:text-white">
                <h3 className='text-3xl md:text-4xl font-medium ui-serif'>Kategorite kryesore</h3>
            </div>
            <div className="mt-6 px-2 3xl:mx-auto max-w-screen-2xl mx-auto space-y-12 grid md:grid-cols-2 md:gap-2 md:space-y-0 lg:grid-cols-4 lg:gap-x-2 lg:space-y-0 space-y-2">
                <Link to='/prona'>
                    <div style={{ height: "500px" }} className="group relative aspect-h-1 aspect-w-2 overflow-hidden rounded sm:aspect-none sm:relative">
                        <img
                            src="/data/realestate.jpg"
                            alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters."
                            className="object-cover object-center group-hover:opacity-75 absolute inset-0 sm:h-full sm:w-full"
                        />
                        <div
                            aria-hidden="true"
                            className="bg-gradient-to-b from-transparent to-black opacity-50 absolute inset-0"
                        />
                        <div className="flex items-end p-6 absolute inset-0">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                        Real estate
                                    </p>
                                </h3>

                            </div>
                        </div>
                    </div>
                </Link>
                <Link to='/prona'>
                    <div style={{ height: "500px" }} className="group relative aspect-h-1 aspect-w-2 overflow-hidden rounded sm:aspect-none sm:relative">
                        <img
                            src="/data/cars.jpg"
                            alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters."
                            className="object-cover object-center group-hover:opacity-75 absolute inset-0 sm:h-full sm:w-full"
                        />
                        <div
                            aria-hidden="true"
                            className="bg-gradient-to-b from-transparent to-black opacity-50 absolute inset-0"
                        />
                        <div className="flex items-end p-6 absolute inset-0">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                        Cars
                                    </p>
                                </h3>

                            </div>
                        </div>
                    </div>
                </Link>
                <Link to='/prona'>
                    <div style={{ height: "500px" }} className="group relative aspect-h-1 aspect-w-2 overflow-hidden rounded sm:aspect-none sm:relative">
                        <img
                            src="/data/yachts.jpg"
                            alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters."
                            className="object-cover object-center group-hover:opacity-75 absolute inset-0 sm:h-full sm:w-full"
                        />
                        <div
                            aria-hidden="true"
                            className="bg-gradient-to-b from-transparent to-black opacity-50 absolute inset-0"
                        />
                        <div className="flex items-end p-6 absolute inset-0">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                        Yahts
                                    </p>
                                </h3>

                            </div>
                        </div>
                    </div>
                </Link>
                <Link to='/prona'>
                    <div style={{ height: "500px" }} className="group relative aspect-h-1 aspect-w-2 overflow-hidden rounded sm:aspect-none sm:relative">
                        <img
                            src="/data/jets.jpg"
                            alt="Wooden shelf with gray and olive drab green baseball caps, next to wooden clothes hanger with sweaters."
                            className="object-cover object-center group-hover:opacity-75 absolute inset-0 sm:h-full sm:w-full"
                        />
                        <div
                            aria-hidden="true"
                            className="bg-gradient-to-b from-transparent to-black opacity-50 absolute inset-0"
                        />
                        <div className="flex items-end p-6 absolute inset-0">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <p aria-hidden="true" className="mt-1 text-sm text-white">
                                        Jobs
                                    </p>
                                </h3>

                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="mx-auto max-w-screen-2xl px-2 2xl:px-0">
                <div className='pt-10'>
                    <PropertiesCarousel properties={data?.data} />
                </div>
                <div className='py-10'>
                    <BigHorizontalBanner />
                </div>
                <div className='pt-10 pb-10'>
                    <PropertiesCarousel properties={data?.data} />
                </div>
            </div>
        </>
    )
}
