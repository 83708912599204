import { useEffect, useState, useRef } from 'react';
import { getRefValue, getUrlParams, scrollToDiv, truncateString } from '../../../services/helpers';
import Pagination from '../../../components/general/Pagination';
import { broadcrumb, showNotification } from '../../../store/main/MainSlice';
import { useDispatch } from 'react-redux';
import Loading from '../../../components/general/Loading';
import { useFetchAgencyAgentsQuery, useAddAgentMutation } from '../../../store/account/properties/accountPropertiesStore';
import { useNavigate } from 'react-router-dom';
import EFInput from '../../../components/general/EFInput';
import { PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

const pages = [
  { name: 'Prona', href: '/account/properties/dashboard', current: false },
  { name: 'Agency', href: '/account/properties/agency/profile', current: false },
  { name: 'Agents', href: '#', current: true },
]

export default function AgencyAgents() {

  const params = getUrlParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //#region rtk
  const { data, isFetching } = useFetchAgencyAgentsQuery(params);
  const [addAgent] = useAddAgentMutation();
  //#endregion

  //#region refs
  const refCheckbox = useRef<any>();
  const refAgentCode = useRef<any>();
  //#endregion

  //#region states
  const [page, setPage] = useState(params);
  const [checked, setChecked] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [newAgent, setNewAgent] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  //#endregion

  //#region functions
  const handlePageClick = async (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', page.toString());
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    setPage(page);
    scrollToDiv('properties', 80);
  };

  const handleDeleteAgent = async (id: any) => {
    alert('TO DO');
  };

  function toggleAll() {
    setSelectedRows(checked ? [] : data?.data.map((item: any) => item.id))
    setChecked(!checked)
  }
  function handleSelect(e: any, agent: any) {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, agent.id]);
    } else {
      setSelectedRows(selectedRows.filter((row: any) => row !== agent.id))
    }
  }

  const handleAddNewAgent = async (e: any) => {
    e.preventDefault();

    const response: any = await addAgent({ code: getRefValue(refAgentCode) });
    if (response.data?.status) {
      dispatch(showNotification({ msg: response.data.message, visible: true }));
      setNewAgent(false);
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };
  //#endregion

  //#region useEfffect
  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, []);
  //#endregion

  if (isFetching && !data?.meta) {
    return <Loading />;
  }

  return (
    <>
      <div id='properties'>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            {selectedRows.length > 0 && (
              <div className="left-14 top-0 flex items-center space-x-3 bg-white sm:left-12">
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Bulk edit
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Delete all
                </button>
              </div>
            )}
          </div>
          <div className="flex mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {newAgent && <EFInput refValue={refAgentCode} required={true} name='code' placeholder='Vendos kodin e agjentit' validations={errors} />}
            {newAgent && <button
              onClick={handleAddNewAgent}
              style={{ height: "38px" }}
              type="button"
              className="block ml-2 rounded-md bg-neutral-800 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Ruaj
            </button>}

            <button
              onClick={() => setNewAgent(!newAgent)}
              style={{ height: "38px" }}
              type="button"
              className="block ml-2 rounded-md bg-neutral-800 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              {newAgent && <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />}
              {!newAgent && <PlusCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />}
            </button>
          </div>
        </div>
        <table className="min-w-full divide-y divide-neutral-300">
          <thead>
            <tr>
              <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                <input
                  type="checkbox"
                  className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                  ref={refCheckbox}
                  checked={checked}
                  onChange={toggleAll}
                />
              </th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                Emri i agjentit
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Data e krijimit
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Prona te publikuara
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 float-right">
                Veprime
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-neutral-300 bg-white">
            {data?.data.map((agent: any) => (
              <tr key={agent.id} className='even:bg-gray-100'>
                <td className="relative px-7 sm:w-12 sm:px-6">
                  {selectedRows.includes(agent.id) && (
                    <div className="absolute inset-y-0 left-0 w-0.5 bg-red-600" />
                  )}
                  <input
                    type="checkbox"
                    className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                    value={agent.email}
                    checked={selectedRows.includes(agent.id)}
                    onChange={(e) => handleSelect(e, agent)}
                  />
                </td>
                <td className="whitespace-nowrap py-1 pl-4 pr-3 text-sm sm:pl-0">
                  <div className="flex items-center">
                    <div className="h-16 w-24 flex-shrink-0">
                      <img className="h-16 w-24 rounded-sm object-cover" src={agent.image} alt="" />
                    </div>
                    <div className="ml-4">
                      <div className="font-medium text-gray-900">{agent.name}</div>
                      <div className="mt-1 text-gray-500">{truncateString(agent.desc, 50)}</div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                  <div className="text-gray-900">{agent.created}</div>
                </td>
                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                  <div className="text-gray-900">{agent.properties_count}</div>
                </td>
                <td className="whitespace-nowrap flex px-3 py-5 text-sm text-gray-500 float-right">
                  <a href={`/prona/agjent/${agent.id}`} target='_blank'>
                    <button
                      type="button"
                      className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >Shiko profilin</button>
                  </a>
                  <button
                    onClick={() => handleDeleteAgent(agent.id)}
                    type="button"
                    className="inline-flex items-center rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 ml-1 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >Fshi</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={data?.current_page ?? 1}
        from={data?.from ?? 0}
        to={data?.to ?? 0}
        total={data?.total ?? 0}
        last={data?.last_page ?? 0}
        onPageClick={handlePageClick}
      />
    </>
  )
}
