import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "./styles.css";
import CarMainCard from "../cards/CarMainCard";

export default function CarsMainSlider({
  cars,
  slides = 4,
  title = "Kliente te perzgjedhur",
}: {
  cars: any;
  slides?: number;
  title?: string;
}) {
  return (
    <Swiper
      slidesPerView={slides}
      spaceBetween={10}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Navigation]}
      loop={true}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      breakpoints={{
        0: {
          slidesPerView: slides - 3,
        },
        768: {
          slidesPerView: slides - 2,
        },
        1024: {
          slidesPerView: slides - 1,
        },
        1800: {
          slidesPerView: slides,
        },
      }}
      className="propertiesCarousel"
    >
      <h2 className="absolute top-0 text-2xl md:text-3xl font-medium ui-serif py-2 dark:text-white">
        {title}
      </h2>
      <div className="navTopRight hidden md:flex">
        <div className="swiper-button-prev navs"></div>
        <div className="swiper-button-next navs"></div>
      </div>
      {cars?.length &&
        cars.map((car: any) => (
          <SwiperSlide key={car.id}>
            <CarMainCard key={car.id} car={car} />
          </SwiperSlide>
        ))}
    </Swiper>
  );
}
