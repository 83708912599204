import React, { ReactNode } from "react";
import AccountSidebar from "../AccountSidebar";
import { useSelector } from "react-redux";
import NotificationAlert from "../../../general/NotificationAlert";

import {
  ChartPieIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  HomeModernIcon,
  ListBulletIcon,
  PlusCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useLocation } from "react-router-dom";

interface NavigationItem {
  id: number;
  name: string;
  href: string;
  icon: React.ElementType; // Assuming icons are React components
  role: string[];
  logout?: boolean;
  current?: boolean;
}

interface LayoutSelectorProps {
  children: ReactNode;
}

const PropertiesPersonLayout: React.FC<LayoutSelectorProps> = ({
  children,
}) => {
  const generalData = useSelector((state: any) => state.main.generalData);

  const location = useLocation();
  let navigation: NavigationItem[] = [
    {
      id: 1,
      name: "Llogaria kryesore",
      href: "/account/main/dashboard",
      icon: HomeIcon,
      role: [],
      current: location.pathname === "/account/main/dashboard",
    },
    {
      id: 2,
      name: "Dashboard",
      href: "/account/properties/dashboard",
      icon: HomeModernIcon,
      role: [],
      current: location.pathname === "/account/properties/dashboard",
    },
    {
      id: 3,
      name: "Profili",
      href: "/account/properties/person/profile",
      icon: UserIcon,
      role: [],
      current: location.pathname === "/account/properties/person/profile",
    },
    {
      id: 4,
      name: "Shto prone",
      href: "/account/properties/person/create-property",
      icon: PlusCircleIcon,
      role: [],
      current:
        location.pathname === "/account/properties/person/create-property",
    },
    {
      id: 5,
      name: "Lista e pronave",
      href: "/account/properties/person/property-list",
      icon: ListBulletIcon,
      role: [],
      current: location.pathname === "/account/properties/person/property-list",
    },
    {
      id: 6,
      name: "Pronat e ruajtura",
      href: "/account/properties/person/saved",
      icon: HeartIcon,
      role: [],
      current: location.pathname === "/account/properties/person/saved",
    },
    {
      id: 7,
      name: "Mesazhe",
      href: "/account/properties/person/contacts",
      icon: ChatBubbleBottomCenterTextIcon,
      role: [],
      current: location.pathname === "/account/properties/person/contacts",
    },
    {
      id: 8,
      name: "Raporte",
      href: "#",
      icon: ChartPieIcon,
      role: [],
      current: location.pathname === "#",
    },
  ];

  if (generalData?.roles) {
    if (!generalData.roles.includes("propertyAgency")) {
      navigation = navigation.filter(
        (item) => !item.role.includes("propertyAgency")
      );
    }
  }

  return (
    <div>
      <NotificationAlert />
      <AccountSidebar navigation={navigation} />
      <main className="pt-10 lg:pl-72 pt-20">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </main>
    </div>
  );
};

export default PropertiesPersonLayout;
