import { useFetchAgentQuery } from "../../../store/properties/PropertiesStore";
import { useParams } from "react-router-dom";
import {
  findInObjects,
  getUrlParams,
  isNumeric,
  mapAddress,
  scrollToDiv,
} from "../../../services/helpers";
import NotFound from "../../NotFound";
import Loading from "../../../components/general/Loading";
import { Cities } from "../../../services/constants";
import PropertyMainCard from "../../../components/front/cards/PropertyMainCard";
import Pagination from "../../../components/general/Pagination";
import { useState } from "react";
import PropertyModalFilters from "../../../components/front/filters/PropertyModalFilters";
import ContactSeller from "../../../components/general/contacts/ContactSeller";

export default function AgentDetails() {
  //#region costants
  const { id } = useParams();
  const params = getUrlParams();
  let agent: any = {};
  //#endregion

  //#region rtk
  const { data, isFetching } = useFetchAgentQuery({ id: id, ...params });
  //#endregion

  //#region rtk
  const [page, setPage] = useState(params);
  //#endregion

  if (isFetching && !data?.data.id) {
    return <Loading />;
  } else {
    agent = data.data;
  }

  const handlePageClick = async (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set("page", page.toString());
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setPage(page);
    scrollToDiv("properties", 80);
  };

  if (!isNumeric(id)) {
    return <NotFound />;
  }

  const onFiltersChange = async (data: any) => {
    const params = new URLSearchParams(data);
    if (params.has("page") && params.get("page") !== "1") {
      params.set("page", "1");
    }
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params.toString()}`
    );
    setPage(params);
  };

  const mapUrl = `https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${encodeURIComponent(
    mapAddress(agent.address, findInObjects(Cities, "id", "name", agent.city))
  )}&z=14&output=embed`;

  return (
    <>
      <div className="bg-zinc-200 py-4">
        <div className="mx-auto max-w-screen-2xl px-2 sm:px-0">
          <div className="flex justify-start pt-8">
            <p className="text-sm text-gray-500">Agjensi pronash</p>
            <p className="text-sm text-gray-500 px-2">|</p>
            <p className="text-sm text-gray-500">
              {" "}
              ({data.data?.pagination?.total} prona te listuara){" "}
            </p>
          </div>
          <h3 className="text-4xl sm:text-7xl ui-serif sm:my-8">
            {agent.name}
          </h3>
        </div>
      </div>
      <div className="mx-auto max-w-screen-2xl mt-2">
        <div className="grid grid-cols-12 gap-4 relative px-2">
          <div className="order-2 md:order-1 col-span-12 md:col-span-7 lg:col-span-8 2xl:col-span-8">
            <h3 className="text-xl ui-serif mt-2">Pershkrim</h3>
            <p className="text-sm text-gray-500"> {agent.desc}</p>
            <h3 className="text-xl ui-serif mt-2">Data e regjistrimit</h3>
            <p className="text-sm text-gray-500"> {agent.created}</p>
            <h3 className="text-xl ui-serif mt-2">Adresa</h3>
            <p className="text-sm text-gray-500">
              {" "}
              {agent.address}, {findInObjects(Cities, "id", "name", agent.city)}
            </p>

            {agent.facebook && (
              <>
                <h3 className="text-xl ui-serif mt-2">Facebook</h3>
                <p className="text-sm text-gray-500"> {agent.facebook}</p>
              </>
            )}
            {agent.instagram && (
              <>
                <h3 className="text-xl ui-serif mt-2">Instagram</h3>
                <p className="text-sm text-gray-500"> {agent.instagram}</p>
              </>
            )}
            {agent.twitter && (
              <>
                <h3 className="text-xl ui-serif mt-2">Twitter</h3>
                <p className="text-sm text-gray-500"> {agent.twitter}</p>
              </>
            )}
            {agent.tiktok && (
              <>
                <h3 className="text-xl ui-serif mt-2">Tiktok</h3>
                <p className="text-sm text-gray-500"> {agent.tiktok}</p>
              </>
            )}

            <iframe
              className="mt-8"
              width="100%"
              height="400"
              allowFullScreen
              src={mapUrl}
              title="Google Maps"
            />
            <div className="bg-white z-50 mt-8">
              <h3 id="properties" className="text-2xl my-4 ui-serif">
                Prona nga {agent?.name}
              </h3>
              <div id="properties">
                <PropertyModalFilters onFiltersChange={onFiltersChange} />
              </div>
              <div className="bg-white">
                <div className="mx-auto max-w-screen-2xl px-2 py-5 2xl:px-0 sm:py-5">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 gap-y-4 gap-x-4">
                    {agent?.properties.map((property: any) => (
                      <PropertyMainCard key={property.id} property={property} />
                    ))}
                  </div>
                  <Pagination
                    currentPage={agent?.pagination.current_page ?? 1}
                    from={agent?.pagination.from ?? 0}
                    to={agent?.pagination.to ?? 0}
                    total={agent?.pagination.total ?? 0}
                    last={agent?.pagination.last_page ?? 0}
                    onPageClick={handlePageClick}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="order-1 md:order-2 md:sticky col-span-12 md:col-span-5 lg:col-span-4 2xl:col-span-4 top-20 h-fit">
            <ContactSeller
              seller={data.data}
              module="PROPERTIES"
              description={`Pershendetje ${data.data?.name}!`}
              whatsapp={`Pershendetje, `}
            />
          </div>
        </div>
      </div>
    </>
  );
}
