import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeader from "../../../src/services/apiHeaders";

// API endpoint for fetching data
const url = process.env.REACT_APP_API_BASE_URL;

export const propertiesApi = createApi({
    reducerPath: "properties_r",
    baseQuery: fetchBaseQuery({
        //   baseUrl: process.env.API_SERVER_URL + process.env.API_V1,
        baseUrl: url + "properties",
        fetchFn: async (...args) => {
            return fetch(...args);
        },
        prepareHeaders(headers) {
            return getHeader(headers);
        },
    }),

    endpoints: (builder) => ({
        fetchProperties: builder.query({
            query: () => {
                return {
                    url: "home",
                    method: "GET",
                };
            },
        }),
        fetchFilteredProperties: builder.query({
            query: (params) => {
                return {
                    url: "properties-list",
                    method: "GET",
                    params: params,
                };
            },
        }),
        fetchProperty: builder.query({
            query: (params) => {
                return {
                    url: "property",
                    method: "GET",
                    params: params
                };
            },
        }),
        fetchPropertyAgencies: builder.query({
            query: (params) => {
                return {
                    url: "agencies",
                    method: "GET",
                    params: params,
                };
            },
        }),
        fetchAgency: builder.query({
            query: (params) => {
                return {
                    url: "agency",
                    method: "GET",
                    params: params
                };
            },
        }),

        fetchPropertyAgents: builder.query({
            query: (params) => {
                return {
                    url: "agents",
                    method: "GET",
                    params: params,
                };
            },
        }),
        fetchAgent: builder.query({
            query: (params) => {
                return {
                    url: "agent",
                    method: "GET",
                    params: params
                };
            },
        }),

        saveProperty: builder.mutation({
            query: (body) => ({
                url: '/account/save-property',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useFetchFilteredPropertiesQuery,
    useFetchPropertiesQuery,
    useFetchPropertyQuery,
    useFetchPropertyAgenciesQuery,
    useFetchAgencyQuery,
    useFetchPropertyAgentsQuery,
    useFetchAgentQuery,
    useSavePropertyMutation
} = propertiesApi;
