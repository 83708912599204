import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/front/main/Home';
import Dashboard from '../pages/account/main/Dashboard';
import LayoutSelector from '../components/LayoutSelector';
import AboutUs from '../pages/front/main/About';
import ContactUs from '../pages/front/main/ContactUs';
import LoginPage from '../pages/auth/Login';
import RegisterPage from '../pages/auth/Register';
import Properties from '../pages/front/properties/Home';
import PropertyList from '../pages/front/properties/PropertyList';
import PropertyDetails from '../pages/front/properties/PropertyDetails';
import AgencyDetails from '../pages/front/properties/AgencyDetails';
import NotFound from '../pages/NotFound';
import Agencies from '../pages/front/properties/Agencies';
import Agents from '../pages/front/properties/Agents';
import AgentDetails from '../pages/front/properties/AgentDetails';
import AgencyProfile from '../pages/account/properties/AgencyProfile';
import CarAgencyProfile from '../pages/account/cars/AgencyProfile';
import CreateProperty from '../pages/account/properties/Create';
import AccountPropertyList from '../pages/account/properties/PropertyList';
import AgentProfile from '../pages/account/properties/AgentProfile';
import MainProfile from '../pages/account/main/MainProfile';
import PropertySaves from '../pages/account/properties/PropertySaves';
import AgencyAgents from '../pages/account/properties/AgencyAgents';
import Contacts from '../pages/account/properties/Contacts';
import Edit from '../pages/account/properties/Edit';
import PersonProfile from '../pages/account/properties/PersonProfile';

import Cars from '../pages/front/cars/Home';
import CarList from '../pages/front/cars/CarList';

const FrontRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/" element={<Home />} />
      <Route path="/rreth-nesh" element={<AboutUs />} />
      <Route path="/kontakt" element={<ContactUs />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const PropertiesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Properties />} />
      <Route path="/lista" element={<PropertyList />} />
      <Route path="/agjensi" element={<Agencies />} />
      <Route path="/:id" element={<PropertyDetails />} />
      <Route path="agjensi/:id" element={<AgencyDetails />} />
      <Route path="/agjente" element={<Agents />} />
      <Route path="agjent/:id" element={<AgentDetails />} />
      <Route path="/rreth-nesh" element={<AboutUs />} />
      <Route path="/kontakt" element={<ContactUs />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const CarsRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Cars />} />
      <Route path="/lista" element={<CarList />} />
      {/* <Route path="/agjensi" element={<Agencies />} />
      <Route path="/:id" element={<PropertyDetails />} />
      <Route path="agjensi/:id" element={<AgencyDetails />} />
      <Route path="/agjente" element={<Agents />} />
      <Route path="agjent/:id" element={<AgentDetails />} /> */}
      <Route path="/rreth-nesh" element={<AboutUs />} />
      <Route path="/kontakt" element={<ContactUs />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

//#region auth routes
const AuthPropertiesRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Auth agency routes */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/agency/profile" element={<AgencyProfile />} />
      <Route path="/agency/property-list" element={<AccountPropertyList />} />
      <Route path="/agency/saved" element={<PropertySaves />} />
      <Route path="/agency/create-property" element={<CreateProperty />} />
      <Route path="/agency/property/:id" element={<Edit />} />
      <Route path="/agency/agents" element={<AgencyAgents />} />
      <Route path="/agency/contacts" element={<Contacts />} />


      {/* Auth agent routes */}
      <Route path="/agent/profile" element={<AgentProfile />} />
      <Route path="/agent/create-property" element={<CreateProperty />} />
      <Route path="/agent/property-list" element={<AccountPropertyList />} />
      <Route path="/agent/saved" element={<PropertySaves />} />
      <Route path="/agent/contacts" element={<Contacts />} />

      {/* Auth person routes */}
      <Route path="/person/profile" element={<PersonProfile />} />
      <Route path="/person/create-property" element={<CreateProperty />} />
      <Route path="/person/property-list" element={<AccountPropertyList />} />
      <Route path="/person/saved" element={<PropertySaves />} />
      <Route path="/person/contacts" element={<Contacts />} />
    </Routes>
  );
};

const AuthCarsRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Auth agency routes */}
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/agency/profile" element={<CarAgencyProfile />} />
      <Route path="/agency/car-list" element={<AccountPropertyList />} />
      <Route path="/agency/saved" element={<PropertySaves />} />
      <Route path="/agency/create-car" element={<CreateProperty />} />
      <Route path="/agency/property/:id" element={<Edit />} />
      <Route path="/agency/agents" element={<AgencyAgents />} />
      <Route path="/agency/contacts" element={<Contacts />} />

      {/* Auth person routes */}
      <Route path="/person/profile" element={<PersonProfile />} />
      <Route path="/person/create-property" element={<CreateProperty />} />
      <Route path="/person/property-list" element={<AccountPropertyList />} />
      <Route path="/person/saved" element={<PropertySaves />} />
      <Route path="/person/contacts" element={<Contacts />} />
    </Routes>
  );
};

const AuthRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Main dashboard routes */}
      <Route path="/main/dashboard" element={<Dashboard />} />
      <Route path="/main/profile" element={<MainProfile />} />
      <Route path="/clients" element={<Dashboard />} />

      {/* Auth properties routes */}
      <Route path="/properties/*" element={<AuthPropertiesRoutes />} />
      <Route path="/cars/*" element={<AuthCarsRoutes />} />
    </Routes>
  );
};
//#endregion

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <LayoutSelector>
        <Routes>
          <Route path="/*" element={<FrontRoutes />} />
          <Route path="/account/*" element={<AuthRoutes />} />
          <Route path="/prona/*" element={<PropertiesRoutes />} />
          <Route path="/automjete/*" element={<CarsRoutes />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </LayoutSelector>
    </Router>
  );
};

export default AppRoutes;
