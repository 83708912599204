export default function BigHorizontalBanner() {
    return (
        <div className="mx-auto max-w-screen-2xl">
            <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 pb-16 shadow-2xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0 lg:pb-0">
                <div className="mx-auto max-w-lg text-center lg:mx-0 lg:flex-auto lg:py-20 lg:text-left">
                    <h2 className="text-3xl font-medium tracking-tight text-white sm:text-4xl ui-serif">
                        Boost your productivity.
                        <br />
                        Start using our app today.
                    </h2>
                    <p className="mt-6 text-lg leading-8 text-gray-300">
                        Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                        <a
                            href="#"
                            className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                        >
                            Get started
                        </a>
                        <a href="#" className="text-sm font-semibold leading-6 text-white">
                            Learn more <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>
                <img
                    className="absolute left-0 top-0 max-w-none z-[-1] h-full"
                    src="/data/home.jpg"
                    alt="App screenshot"
                    width={1824}
                    height={1080}
                />
            </div>
        </div>
    )
}
