import React from 'react'; // Import React

// Define a type for your component's props
type LoadingProps = {
  h?: string; // Make the `h` prop optional and of type string
};

const Loading: React.FC<LoadingProps> = ({ h = 'h-screen' }) => {
  return (
    <div className={`flex items-center justify-center ${h} -mt-10`}>
      <div className="animate-spin rounded-full border-t-4 border-neutral-800 border-t-red-600 h-12 w-12"></div>
    </div>
  );
};

export default Loading;
