import { useEffect, useState } from 'react';
import { useUpdatePropertyMutation, useFetchPropertyEditQuery, useUpdateImagePriorityMutation, useDeleteImageMutation } from '../../../store/account/properties/accountPropertiesStore';
import { useDispatch } from 'react-redux';
import { broadcrumb, showNotification } from '../../../store/main/MainSlice';
import FileUpload from '../../../components/general/FileUpload';
import { Cities, CreatePropertyStatuses, PropertyBathrooms, PropertyFurnitures, PropertyRooms, PropertyTypes } from '../../../services/constants';
import SingleSelect from '../../../components/general/SingleSelect';
import { isNotNullOrUndefined } from '../../../services/helpers';
import { Zones } from '../../../services/zones';
import { useLocation, useParams } from 'react-router-dom';
import EFInput from '../../../components/general/EFInput';
import CkEdit from '../../../components/general/CkEdit';
import Loading from '../../../components/general/Loading';
import { XMarkIcon } from '@heroicons/react/24/outline';


const pages = [
  { name: 'Prona', href: '/account/properties/dashboard', current: false },
  { name: 'Detaje', href: '#', current: true },
]

export default function EditProperty() {

  //#region variables
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  let actAs = 3;
  if (location.pathname.startsWith('/account/properties/agency')) actAs = 1;
  if (location.pathname.startsWith('/account/properties/agent')) actAs = 2;
  //#endregion

  //#region rtk
  const [updateProperty] = useUpdatePropertyMutation();
  const [updateImagePriority] = useUpdateImagePriorityMutation();
  const [deleteImage] = useDeleteImageMutation();
  const { data, isFetching, refetch } = useFetchPropertyEditQuery({ id: id });
  //#endregion

  //#region states
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>();
  const [cityZones, setCityZones] = useState<any>();
  //#endregion

  //#region functions
  const handleChange = (e: any, key: string) => {
    let value = e;
    if (key === 'city_id' || key === 'status_id' || key === 'type_id' || key === 'zone_id' || key === 'bathrooms' || key === 'rooms' || key === 'furniture_id') {
      value = e;
    } else {
      value = e.target.value;
    }

    //Filter zones based on city
    if (key === 'city_id') setCityZones(Zones.filter(x => x.city_id === value));

    //Remove from state keys that are not related with type, update state
    if (key === 'type_id' && (value != 1 || value != 4)) {
      const updatedFormData = { ...formData };
      delete updatedFormData['rooms'];
      delete updatedFormData['furniture_id'];
      delete updatedFormData['bathrooms'];
      setFormData({
        ...updatedFormData,
        [key]: value,
      });
      return;
    }

    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleChangeEditor = (e: any, editor: any) => {
    if (formData?.id) {
      setFormData({
        ...formData,
        ["description"]: editor.getData(),
      });
    }
  };

  const handleFilesChange = async (e: any) => {
    setFormData({
      ...formData,
      ['image']: e,
    });
  };

  const handleUpdateSubmit = async (e: any) => {
    e.preventDefault();
    formData.actAs = actAs;
    const response: any = await updateProperty(formData);
    if (response.data?.status) {
      dispatch(showNotification({ msg: "U perditesua me suksess!", visible: true }));
      refetch();
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };

  const handleChangeImagePriority = async (id: number) => {
    const params = {
      propertyId: formData.id,
      imageId: id
    }
    const response: any = await updateImagePriority(params);
    if (response.data?.status) {
      dispatch(showNotification({ msg: "U perditesua me suksess!", visible: true }));
      refetch();
    }
  };

  const handleDeleteImage = async (id: number) => {

    const response: any = await deleteImage({
      propertyId: formData.id,
      imageId: id
    });
    if (response.data?.status) {
      dispatch(showNotification({ msg: "U fshi me sukses!", visible: true }));
      refetch();
    }
  };
  //#endregion

  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, []);

  useEffect(() => {
    if (isNotNullOrUndefined(data)) {
      let property = {
        id: data.id,
        name: data.name,
        status_id: data.status_id,
        type_id: data.type_id,
        city_id: data.city_id,
        zone_id: data.zone_id,
        rooms: data.rooms,
        bathrooms: data.bathrooms,
        furniture_id: data.furniture_id,
        address: data.address,
        price: data.price,
        surface: data.surface,
        floor: data.floor,
        description: data.description,
      }
      setFormData(property);

    }
  }, [data]);

  if (isFetching && !formData?.id) return <Loading />


  return (
    <>
      <div className="grid grid-cols-12 gap-4 mt-4">
        <div className="col-span-12">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">
            <EFInput onChange={handleChange} label='Titulli i prones' name='name' defaultValue={formData.name} validations={errors} />
            <div className='-mt-2'>
              <SingleSelect className="mt-0" onSelectChange={(e: any) => handleChange(e, 'status_id')} items={CreatePropertyStatuses} name='status_id' label="Statusi" defaultValue={formData.status_id} validations={errors} />
            </div>
            <div className='-mt-2'>
              <SingleSelect onSelectChange={(e: any) => handleChange(e, 'type_id')} items={PropertyTypes} name='type_id' label="Lloji i prones" defaultValue={formData.type_id} validations={errors} />
            </div>
            <div className='-mt-2'>
              <SingleSelect className="mt-0" onSelectChange={(e: any) => handleChange(e, 'city_id')} items={Cities} name='city_id' defaultValue={formData?.city_id} label="Zgjidh qytetin" validations={errors} />
            </div>
            {isNotNullOrUndefined(formData) && formData.city_id &&
              <div className='-mt-2'>
                <SingleSelect className="mt-0" onSelectChange={(e: any) => handleChange(e, 'zone_id')} items={cityZones} defaultValue={formData?.zone_id} label="Zgjidh zonen" validations={errors} />
              </div>
            }
            {(formData.type_id === 1 || formData.type_id === 4) &&
              <div className='-mt-2'>
                <SingleSelect className="mt-0" onSelectChange={(e: any) => handleChange(e, 'rooms')} items={PropertyRooms} defaultValue={formData?.rooms} name='rooms' label="Dhoma" validations={errors} />
              </div>
            }
            {(formData.type_id === 1 || formData.type_id === 4) &&
              <div className='-mt-2'>
                <SingleSelect className="mt-0" onSelectChange={(e: any) => handleChange(e, 'bathrooms')} defaultValue={formData?.bathrooms} items={PropertyBathrooms} name='bathrooms' label="Tualete" validations={errors} />
              </div>
            }
            {(formData.type_id === 1 || formData.type_id === 4) &&
              <div className='-mt-2'>
                <SingleSelect className="mt-0" onSelectChange={(e: any) => handleChange(e, 'furniture_id')} defaultValue={formData?.furniture_id} items={PropertyFurnitures} name='furniture_id' label="Mobilimi" validations={errors} />
              </div>
            }
            <EFInput onChange={handleChange} label='Adresa' name='address' defaultValue={formData.address} validations={errors} />
            <EFInput onChange={handleChange} type='number' label='Cmimi' defaultValue={formData?.price} name='price' placeholder='Vendosni cmimin' validations={errors} />
            <EFInput onChange={handleChange} type='number' label='Siperfaqja' defaultValue={formData?.surface} name='surface' placeholder='Siperfaqja' validations={errors} />
            {formData.type_id != 2 &&
              <EFInput onChange={handleChange} type='number' label='Kati' defaultValue={formData?.floor} name='floor' placeholder='Kati' validations={errors} />
            }
          </div>
          <div className="col-span-full">
            <div className="mt-2">
              <CkEdit onChange={handleChangeEditor} label='Pershkrimi' defaultValue={formData?.description} name='description' validations={errors} />
            </div>
          </div>
          <div className="col-span-full">
            <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-4">Fotot e prones</label>
            <p>Per te percaktuar se cila foto do te shfaqet ne fillim tek prona klikoni mbi foto.</p>
            <div className="grid grid-cols-4 mt-2">
              {data?.images?.map((file: any, index: number) => (
                <div key={index} className={`relative ${file.priority === 1 ? 'border border-zinc-500' : ''}`}>
                  <img
                    onClick={() => handleChangeImagePriority(file.id)}
                    src={file.url}
                    alt={file.name}
                    className="p-2 object-cover w-full aspect-video"
                  />
                  <button
                    onClick={() => handleDeleteImage(file.id)}
                    className="absolute top-0 right-0 p-1 bg-neutral-800 rounded-full"
                  >
                    <XMarkIcon className='h-6 w-6 text-white' />
                  </button>
                </div>
              ))}
            </div>
          </div>
          <label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white mt-2">Shto foto te tjera</label>
          <div className="my-2 col-span-full">
            <FileUpload onFilesChange={(e) => handleFilesChange(e)} cols={4} validations={errors} clearFiles={isFetching} />
          </div>
          <div className="grid grid-col-3">
          </div>
        </div>
      </div >
      <button
        type="button"
        className="mt-2 inline-flex justify-center rounded-md bg-neutral-800 dark:bg-white dark:text-zinc-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
        onClick={handleUpdateSubmit}
      >
        Perditeso
      </button>
    </>

  )
}
