import React, { ReactNode } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import NotificationAlert from '../../../general/NotificationAlert';

interface LayoutSelectorProps {
    children: ReactNode;
}

const navigation = [
    { name: 'Home', href: '/', current: true },
    { name: 'Prona', href: '/prona', current: false },
    { name: 'Lista e pronave', href: '/prona/lista', current: false },
    { name: 'Agjensi', href: '/prona/agjensi', current: false },
    { name: 'Agjente', href: '/prona/agjente', current: false },
    { name: 'Rreth nesh', href: '/prona/rreth-nesh', current: false },
    { name: 'Na kontaktoni', href: '/prona/kontakt', current: false },
]

const FrontLayout: React.FC<LayoutSelectorProps> = ({ children }) => {

    return (
        <div>
            <NotificationAlert />
            <Header navigation={navigation} />
            <main className='mt-14'>{children}</main>
            <Footer />
        </div>
    );
};

export default FrontLayout;
