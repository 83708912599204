import EFInput from "../../../components/general/EFInput";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { broadcrumb, showNotification } from "../../../store/main/MainSlice";
import {
  useChangePasswordMutation,
  useUpdateProfileMutation,
} from "../../../store/auth/AuthStore";

const pages = [
  { name: "Main", href: "/account/main/dashboard", current: false },
  { name: "Profili", href: "#", current: true },
];

export default function MainProfile() {
  //#region rtk
  const [updateProfile] = useUpdateProfileMutation();
  const [changePassword] = useChangePasswordMutation();
  //#endregion

  //#region costants
  const dispatch = useDispatch();
  const generalData = useSelector((state: any) => state.main.generalData);
  //#endregion

  //#region states
  const [profileData, setProfileData] = useState<any>({
    username: null,
    phone: null,
    email: null,
  });

  const [passwordData, setPasswordData] = useState<any>({
    password: null,
    confirmPassword: null,
  });

  const [errors, setErrors] = useState<any>();
  //#endregion

  //#region finctions
  const handleProfileChange = (e: any, key: string) => {
    setProfileData({
      ...profileData,
      [key]: e.target.value,
    });
  };

  const handlePasswordChange = (e: any, key: string) => {
    setPasswordData({
      ...passwordData,
      [key]: e.target.value,
    });
  };

  const handleUpdateProfile = async (e: any) => {
    e.preventDefault();
    const response: any = await updateProfile(profileData);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };

  const handleUpdatePassword = async (e: any) => {
    if (passwordData.password !== passwordData.confirmPassword) {
      return dispatch(
        showNotification({ msg: "Fjalekalimet nuk perputhen!", visible: true })
      );
    }
    e.preventDefault();
    const response: any = await changePassword(passwordData);
    if (response.data?.status) {
      dispatch(
        showNotification({ msg: "U perditesua me suksess!", visible: true })
      );
    }
    if (response.error) {
      setErrors(response.error.data.errors);
    } else {
      setErrors(null);
    }
  };
  //#endregion

  useEffect(() => {
    if (generalData.name) {
      setProfileData({
        username: generalData.name,
        phone: generalData.phone,
        email: generalData.email,
      });
    }
  }, [generalData]);

  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, [dispatch]);

  return (
    <>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 sm:col-span-6">
          <div className="bg-neutral-800 w-full text-white p-2 mt-4">
            Te dhena te pergjithshme
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 gap-2 mt-2">
            <EFInput
              onChange={handleProfileChange}
              label="Emri i plote"
              required={true}
              defaultValue={profileData?.username}
              name="username"
              validations={errors}
              hasIcon={true}
            />
            <EFInput
              onChange={handleProfileChange}
              label="Email"
              required={true}
              defaultValue={profileData?.email}
              name="email"
              validations={errors}
              hasIcon={true}
            />
            <EFInput
              onChange={handleProfileChange}
              label="Telefon (Ky numer do te perdoret si kontakt ne postet tuaja)"
              required={true}
              defaultValue={profileData?.phone}
              name="phone"
              validations={errors}
              hasIcon={true}
            />
          </div>
          <button
            type="button"
            className="mt-2 inline-flex justify-center rounded-md bg-neutral-800 dark:zinc-900 dark:text-white px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
            onClick={handleUpdateProfile}
          >
            Ruaj te dhenat
          </button>
        </div>
        <div className="col-span-12 sm:col-span-6">
          <div className="bg-neutral-800 w-full text-white p-2 mt-4">Siguria</div>
          <div className="grid grid-cols-1 sm:grid-cols-1 gap-2 mt-2">
            <EFInput
              onChange={handlePasswordChange}
              label="Fjalekalim i ri"
              required={true}
              name="password"
              validations={errors}
              type="password"
              hasIcon={true}
            />
            <EFInput
              onChange={handlePasswordChange}
              label="Konfirmo fjalekalimin"
              required={true}
              name="confirmPassword"
              validations={errors}
              type="password"
              hasIcon={true}
            />
          </div>
          <button
            type="button"
            className="mt-2 inline-flex justify-center rounded-md bg-neutral-800 dark:zinc-900 dark:text-white px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-900"
            onClick={handleUpdatePassword}
          >
            Ndrysho fjalekalimin
          </button>
        </div>
      </div>
    </>
  );
}
