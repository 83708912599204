export const Zones = [
    {
        "id": 1,
        "city_id": 1,
        "name": "Kucove",
    },
    {
        "id": 2,
        "city_id": 1,
        "name": "Qender",
    },
    {
        "id": 3,
        "city_id": 1,
        "name": "Zone periferike",
    },
    {
        "id": 4,
        "city_id": 2,
        "name": "Qender",
    },
    {
        "id": 5,
        "city_id": 2,
        "name": "Zone Periferike",
    },
    {
        "id": 6,
        "city_id": 3,
        "name": "Qender",
    },
    {
        "id": 7,
        "city_id": 3,
        "name": "Zone Periferike",
    },
    {
        "id": 8,
        "city_id": 4,
        "name": "Qender",
    },
    {
        "id": 9,
        "city_id": 4,
        "name": "Zone Periferike",
    },
    {
        "id": 10,
        "city_id": 5,
        "name": "Qender",
    },
    {
        "id": 11,
        "city_id": 5,
        "name": "Zone Periferike",
    },
    {
        "id": 12,
        "city_id": 6,
        "name": "Qender",
    },
    {
        "id": 13,
        "city_id": 6,
        "name": "Zone Periferike",
    },
    {
        "id": 14,
        "city_id": 7,
        "name": "Qender",
    },
    {
        "id": 15,
        "city_id": 7,
        "name": "Zone Periferike",
    },
    {
        "id": 16,
        "city_id": 8,
        "name": "Qender",
    },
    {
        "id": 17,
        "city_id": 8,
        "name": "Zone Periferike",
    },
    {
        "id": 18,
        "city_id": 9,
        "name": "Currilat",
    },
    {
        "id": 19,
        "city_id": 9,
        "name": "Gjiri i Lalzit",
    },
    {
        "id": 20,
        "city_id": 9,
        "name": "Golem Shkembi Kavajes",
    },
    {
        "id": 21,
        "city_id": 9,
        "name": "Qender",
    },
    {
        "id": 22,
        "city_id": 9,
        "name": "Plepa",
    },
    {
        "id": 23,
        "city_id": 9,
        "name": "Zone Periferike",
    },
    {
        "id": 24,
        "city_id": 10,
        "name": "Qender",
    },
    {
        "id": 25,
        "city_id": 10,
        "name": "Zone Periferike",
    },
    {
        "id": 26,
        "city_id": 11,
        "name": "Qender",
    },
    {
        "id": 27,
        "city_id": 11,
        "name": "Zone Periferike",
    },
    {
        "id": 28,
        "city_id": 12,
        "name": "Patos",
    },
    {
        "id": 29,
        "city_id": 12,
        "name": "Qender",
    },
    {
        "id": 30,
        "city_id": 12,
        "name": "Zone Periferike",
    },
    {
        "id": 31,
        "city_id": 13,
        "name": "Qender",
    },
    {
        "id": 32,
        "city_id": 13,
        "name": "Zone Periferike",
    },
    {
        "id": 33,
        "city_id": 14,
        "name": "Qender",
    },
    {
        "id": 34,
        "city_id": 14,
        "name": "Zone Periferike",
    },
    {
        "id": 35,
        "city_id": 15,
        "name": "Qender",
    },
    {
        "id": 36,
        "city_id": 15,
        "name": "Zone Periferike",
    },
    {
        "id": 37,
        "city_id": 16,
        "name": "Borsh",
    },
    {
        "id": 38,
        "city_id": 16,
        "name": "Dhermi",
    },
    {
        "id": 39,
        "city_id": 16,
        "name": "Jale",
    },
    {
        "id": 40,
        "city_id": 16,
        "name": "Livadhe",
    },
    {
        "id": 41,
        "city_id": 16,
        "name": "Potam",
    },
    {
        "id": 42,
        "city_id": 16,
        "name": "Qerparo",
    },
    {
        "id": 43,
        "city_id": 16,
        "name": "Qender",
    },
    {
        "id": 44,
        "city_id": 16,
        "name": "Zone periferike",
    },
    {
        "id": 45,
        "city_id": 17,
        "name": "Qerret",
    },
    {
        "id": 46,
        "city_id": 17,
        "name": "Spille",
    },
    {
        "id": 47,
        "city_id": 17,
        "name": "Qender",
    },
    {
        "id": 48,
        "city_id": 17,
        "name": "Zone periferike",
    },
    {
        "id": 49,
        "city_id": 18,
        "name": "Qender",
    },
    {
        "id": 50,
        "city_id": 18,
        "name": "Zone Periferike",
    },
    {
        "id": 51,
        "city_id": 19,
        "name": "Qender",
    },
    {
        "id": 52,
        "city_id": 19,
        "name": "Zone Periferike",
    },
    {
        "id": 53,
        "city_id": 20,
        "name": "Qender",
    },
    {
        "id": 54,
        "city_id": 20,
        "name": "Zone Periferike",
    },
    {
        "id": 55,
        "city_id": 21,
        "name": "Qender",
    },
    {
        "id": 56,
        "city_id": 21,
        "name": "Zone Periferike",
    },
    {
        "id": 57,
        "city_id": 22,
        "name": "Qender",
    },
    {
        "id": 58,
        "city_id": 22,
        "name": "Zone Periferike",
    },
    {
        "id": 59,
        "city_id": 23,
        "name": "Qender",
    },
    {
        "id": 60,
        "city_id": 23,
        "name": "Zone Periferike",
    },
    {
        "id": 61,
        "city_id": 24,
        "name": "Qender",
    },
    {
        "id": 62,
        "city_id": 24,
        "name": "Zone Periferike",
    },
    {
        "id": 63,
        "city_id": 25,
        "name": "Qender",
    },
    {
        "id": 64,
        "city_id": 25,
        "name": "Zone Periferike",
    },
    {
        "id": 65,
        "city_id": 26,
        "name": "Shengjin",
    },
    {
        "id": 66,
        "city_id": 26,
        "name": "Qender",
    },
    {
        "id": 67,
        "city_id": 26,
        "name": "Zone periferike",
    },
    {
        "id": 68,
        "city_id": 27,
        "name": "Qender",
    },
    {
        "id": 69,
        "city_id": 27,
        "name": "Zone Periferike",
    },
    {
        "id": 70,
        "city_id": 28,
        "name": "Divjake",
    },
    {
        "id": 71,
        "city_id": 28,
        "name": "Qender",
    },
    {
        "id": 72,
        "city_id": 28,
        "name": "Zone periferike",
    },
    {
        "id": 73,
        "city_id": 29,
        "name": "Qender",
    },
    {
        "id": 74,
        "city_id": 29,
        "name": "Zone Periferike",
    },
    {
        "id": 75,
        "city_id": 30,
        "name": "Qender",
    },
    {
        "id": 76,
        "city_id": 30,
        "name": "Zone Periferike",
    },
    {
        "id": 77,
        "city_id": 31,
        "name": "Qender",
    },
    {
        "id": 78,
        "city_id": 31,
        "name": "Zone Periferike",
    },
    {
        "id": 79,
        "city_id": 32,
        "name": "Qender",
    },
    {
        "id": 80,
        "city_id": 32,
        "name": "Zone Periferike",
    },
    {
        "id": 81,
        "city_id": 33,
        "name": "Qender",
    },
    {
        "id": 82,
        "city_id": 33,
        "name": "Zone Periferike",
    },
    {
        "id": 83,
        "city_id": 34,
        "name": "Qender",
    },
    {
        "id": 84,
        "city_id": 34,
        "name": "Zone Periferike",
    },
    {
        "id": 85,
        "city_id": 35,
        "name": "Qender",
    },
    {
        "id": 86,
        "city_id": 35,
        "name": "Zone Periferike",
    },
    {
        "id": 87,
        "city_id": 36,
        "name": "Qender",
    },
    {
        "id": 88,
        "city_id": 36,
        "name": "Zone Periferike",
    },
    {
        "id": 89,
        "city_id": 37,
        "name": "Qender",
    },
    {
        "id": 90,
        "city_id": 37,
        "name": "Zone Periferike",
    },
    {
        "id": 91,
        "city_id": 38,
        "name": "Qender",
    },
    {
        "id": 92,
        "city_id": 38,
        "name": "Zone Periferike",
    },
    {
        "id": 93,
        "city_id": 39,
        "name": "Ksamil",
    },
    {
        "id": 94,
        "city_id": 39,
        "name": "Lukove",
    },
    {
        "id": 95,
        "city_id": 39,
        "name": "Qender",
    },
    {
        "id": 96,
        "city_id": 39,
        "name": "Zone periferike",
    },
    {
        "id": 97,
        "city_id": 40,
        "name": "Velipoje",
    },
    {
        "id": 98,
        "city_id": 40,
        "name": "Qender",
    },
    {
        "id": 99,
        "city_id": 40,
        "name": "Zone periferike",
    },
    {
        "id": 100,
        "city_id": 41,
        "name": "Qender",
    },
    {
        "id": 101,
        "city_id": 41,
        "name": "Zone Periferike",
    },
    {
        "id": 102,
        "city_id": 42,
        "name": "21 Dhjetori Rruga e Kavajes",
    },
    {
        "id": 103,
        "city_id": 42,
        "name": "Ali Demi Tregu Elektrik",
    },
    {
        "id": 104,
        "city_id": 42,
        "name": "Astiri Unaza e re Teodor Keko",
    },
    {
        "id": 105,
        "city_id": 42,
        "name": "Blloku Liqeni Artificial",
    },
    {
        "id": 106,
        "city_id": 42,
        "name": "Brryli",
    },
    {
        "id": 107,
        "city_id": 42,
        "name": "Don Bosko",
    },
    {
        "id": 108,
        "city_id": 42,
        "name": "Fresku Linze",
    },
    {
        "id": 109,
        "city_id": 42,
        "name": "Kamez Paskuqan",
    },
    {
        "id": 110,
        "city_id": 42,
        "name": "Kodra e Diellit",
    },
    {
        "id": 111,
        "city_id": 42,
        "name": "Komuna e parisit Stadiumi Dinamo",
    },
    {
        "id": 112,
        "city_id": 42,
        "name": "Laprake",
    },
    {
        "id": 113,
        "city_id": 42,
        "name": "Liqeni i thate Kopshti botanik",
    },
    {
        "id": 114,
        "city_id": 42,
        "name": "Lumi Lana  Bulevard",
    },
    {
        "id": 115,
        "city_id": 42,
        "name": "Ministria e jashte Pazari i ri Shkolla e kuqe",
    },
    {
        "id": 116,
        "city_id": 42,
        "name": "Qyteti Studenti Ambasada USA Vilat Gjermane",
    },
    {
        "id": 117,
        "city_id": 42,
        "name": "Rruga Dritan Hoxha  Shqiponja",
    },
    {
        "id": 118,
        "city_id": 42,
        "name": "Rruga e Durresit Zogu i zi",
    },
    {
        "id": 119,
        "city_id": 42,
        "name": "Rruga e Elbasanit Stadiumi Qemal Stafa",
    },
    {
        "id": 120,
        "city_id": 42,
        "name": "Sauk",
    },
    {
        "id": 121,
        "city_id": 42,
        "name": "Sheshi Shkenderbej Myslym Shyri",
    },
    {
        "id": 122,
        "city_id": 42,
        "name": "Spitali QSUT Xhamlliku Kinostudio",
    },
    {
        "id": 123,
        "city_id": 42,
        "name": "Stacioni trenit Rruga e Dibres Medreseja",
    },
    {
        "id": 124,
        "city_id": 42,
        "name": "Tjeter zone",
    },
    {
        "id": 125,
        "city_id": 42,
        "name": "Vasil Shanto",
    },
    {
        "id": 126,
        "city_id": 42,
        "name": "Vore",
    },
    {
        "id": 127,
        "city_id": 42,
        "name": "Ysberisht Kombinat Selite",
    },
    {
        "id": 128,
        "city_id": 42,
        "name": "Zone Periferike",
    },
    {
        "id": 129,
        "city_id": 43,
        "name": "Lunomare",
    },
    {
        "id": 130,
        "city_id": 43,
        "name": "Narte",
    },
    {
        "id": 131,
        "city_id": 43,
        "name": "Orikum",
    },
    {
        "id": 132,
        "city_id": 43,
        "name": "Plazh i vjeter",
    },
    {
        "id": 133,
        "city_id": 43,
        "name": "Radhime",
    },
    {
        "id": 134,
        "city_id": 43,
        "name": "Uji i ftohte",
    },
    {
        "id": 135,
        "city_id": 43,
        "name": "Qender",
    },
    {
        "id": 136,
        "city_id": 43,
        "name": "Zone periferike",
    },
    {
        "id": 137,
        "city_id": 44,
        "name": "Qender",
    },
    {
        "id": 138,
        "city_id": 44,
        "name": "Zone Periferike",
    }
];