import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChatBubbleBottomCenterIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import PropertyContactModal from "./PropertyContactModal";

type Props = {
  seller: any;
  module: string;
  description: string;
  whatsapp: string;
};

export default function ContactSeller(props: Props) {
  const { seller, module, description, whatsapp } = props;

  //#region refs
  const navigate = useNavigate();
  //#endregion

  //#region refs
  const refContactModal = useRef<any>();
  //#endregion

  //#region functions
  const handleContactBtn = (user_id: number) => {
    if (refContactModal.current) {
      refContactModal.current?.handleContactPopup(user_id, module);
    }
  };
  //#endregion

  return (
    <>
      <PropertyContactModal
        forwardedRef={refContactModal}
        description={description}
      />

      <div className="bg-gray-100 md:p-5 border dark:bg-zinc-900 dark:border-zinc-700">
        <div className="mb-8">
          <div className="flow-root p-2 sm:p-0 sm:flex sm:items-end">
            <img
              alt="Profile img"
              className="w-full sm:w-48 object-contain flex-shrink-0"
              src={seller.image}
            />
            <div className="mt-1 sm:ml-6 flex flex-col sm:flex-1">
              <div className="order-last sm:order-1 mt-3 sm-mt-0">
                <div className="flex items-center">
                  <h3 className="text-xl text-gray-900 sm:text-md dark:text-white">
                    <span
                      onClick={() => navigate(seller.url)}
                      className="cursor-pointer"
                    >
                      {seller.name}
                    </span>
                  </h3>
                </div>
                <a
                  type="button"
                  href={`mailto:${seller.email}`}
                  className="cursor-pointer text-sm text-gray-500 dark:text-white hidden sm:block"
                >
                  {seller.email}
                </a>
              </div>
              <div className="mt-2 sm:mt-5 flex flex-wrap space-y-0 space-x-3 sm:order-last">
                <a
                  type="button"
                  href={`tel:${seller.phone}`}
                  className="inline-flex cursor-pointer flex-1 items-center justify-center rounded-md bg-white dark:bg-zinc-900 dark:text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <PhoneIcon className="h-5 w-5" aria-hidden="true" />
                </a>
                <a
                  rel="noreferrer"
                  type="button"
                  target="_blank"
                  href={`https://wa.me/${seller.phone}?text=${whatsapp}`}
                  className="inline-flex cursor-pointer flex-1 items-center justify-center rounded-md bg-white dark:bg-zinc-900 dark:text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  WhatsApp
                </a>
                <span
                  onClick={() => handleContactBtn(seller.user_id)}
                  className="inline-flex cursor-pointer flex-1 items-center justify-center rounded-md bg-white dark:bg-zinc-900 dark:text-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <ChatBubbleBottomCenterIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="pl-2 pr-2 sm:p-0">
            {seller.created && (
              <>
                <h3 className="text-xl ui-serif mt-2">Data e regjistrimit</h3>
                <p className="text-sm text-gray-500 dark:text-white">
                  {" "}
                  {seller.created}
                </p>
              </>
            )}
            {seller.created && (
              <>
                <h3 className="text-xl ui-serif mt-2">Adresa</h3>
                <p className="text-sm text-gray-500 dark:text-white">
                  {" "}
                  {seller.address}
                </p>
              </>
            )}

            <h3 className="text-xl ui-serif mt-2">Telefon</h3>
            <p className="text-sm text-gray-500 dark:text-white">
              {" "}
              {seller.phone}
            </p>
            <h3 className="text-xl ui-serif mt-2">Email</h3>
            <p className="text-sm text-gray-500 dark:text-white">
              {" "}
              {seller.email}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
