import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default function SinglePropertySlider({ images, start = 0 }: { images: any, start?: number }) {
    return (
        <Swiper
            spaceBetween={0}
            centeredSlides={false}
            autoplay={{
                delay: 3000,
                disableOnInteraction: true,
            }}
            pagination={{
                type: 'fraction',
            }}
            loop={true}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="fullImage"
            initialSlide={start}

        >
            {images.map((image: any, index: any) => (
                <SwiperSlide className='my-auto' key={index}>
                    <img className='mx-auto' src={image.url} alt="" />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}
