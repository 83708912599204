import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface IProperties {
  data: any; // data stored from API
  currIndex: number; // current active car tab index
}

const initialState: IProperties = {
  data: undefined,
  currIndex: 0,
};

// creating action-reducer slice
export const PropertiesSlice = createSlice({
  name: "properties_reducer",
  initialState,
  reducers: {
  // manage/control the currIndex value
    changeCurrIndex: (state, action: PayloadAction<number>) => {
      state.currIndex = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCurrIndex } = PropertiesSlice.actions;

export default PropertiesSlice.reducer;