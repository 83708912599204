import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { BackwardIcon, HeartIcon, ShareIcon, XCircleIcon } from '@heroicons/react/24/outline'
import SinglePropertySlider from '../../../../components/front/slider/SinglePropertySlider';
import { findInObjects, mapAddress } from '../../../../services/helpers';
import { Cities } from '../../../../services/constants';

export default function PropertyModal(props: any) {

    //Constants
    const { open, setOpen, images, propertyData } = props;

    //states
    const [tab, setTab] = useState<string>('images');
    const [showImgeSlider, setShowImgeSlider] = useState({ show: false, slide: 0 })

    const ImageGrid = () => {
        const calculateColSpan = (index: any) => {
            const positionInRow = index % 6;
            const imagesLeft = images.length - index;
            console.log(imagesLeft)

            if (positionInRow === 0) return 6;
            if (positionInRow === 1 || positionInRow === 2) {
                return (imagesLeft === 1) ? 6 : 3;
            }
            if (positionInRow === 3 || positionInRow === 4 || positionInRow === 5) {
                if (imagesLeft === 1) return 6;
                if (imagesLeft > 1 && imagesLeft < 3) return 3;
                return 2;
            }
        };

        return (
            <div className="grid grid-cols-6 gap-4">
                {images.map((image: any, index: number) => {
                    const colSpan = calculateColSpan(index);
                    return (
                        <div key={index} className={`col-span-${colSpan}`}>
                            <img className="aspect-[16/10] w-full object-cover" src={image.url} alt="" onClick={() => handleImageModal(index)} />
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleImageModal = (index: any) => {
        setShowImgeSlider(prevState => ({ ...prevState, show: !showImgeSlider.show, slide: index }));
        setOpen(false);
    }

    return (
        <>
            <div className={`hidden col-span-1`}></div>
            <div className={`hidden col-span-2`}></div>
            <div className={`hidden col-span-3`}></div>

            {showImgeSlider.show &&
                <div className='fixed w-full h-full z-50 bg-neutral-800 top-0 overscroll-none'>
                    <XCircleIcon className="h-8 w-8 cursor-pointer m-2 float-right right-0 fixed text-white z-10" onClick={handleImageModal} />
                    <div className='-mt-5'>
                        <SinglePropertySlider start={showImgeSlider.slide} images={images} />
                    </div>
                </div>
            }
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-20" onClose={() => setOpen(false)}>
                    <div className="fixed inset-0" />
                    <div className="fixed inset-0 overflow-hidden w-full">
                        <div className="absolute inset-0 overflow-hidden">
                            <div className="pointer-events-none fixed inset-y-0 flex w-full max-w-full">
                                <Transition.Child
                                    as={Fragment}
                                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                                    enterFrom="translate-y-full"
                                    enterTo="translate-y-0"
                                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                                    leaveFrom="translate-y-0"
                                    leaveTo="translate-y-full"
                                >
                                    <Dialog.Panel className="pointer-events-auto w-full bg-white dark:bg-zinc-900">
                                        <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-zinc-900 shadow-xl">
                                            <div className='m-2'>
                                                <div className="pr-5 py-3 fixed top-0 w-full bg-white dark:bg-zinc-900 z-10">
                                                    <div className="flex items-start justify-between">
                                                        <div className='flex'>
                                                            <button
                                                                type="button"
                                                                onClick={() => setOpen(false)}
                                                                className="inline-flex items-center gap-x-1.5 rounded-md px-2.5 py-1.5 text-sm font-semibold shadow-sm dark:text-white b-none"
                                                            >
                                                                <BackwardIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                                                Back
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className={`inline-flex items-center gap-x-2 rounded-full py-1.5 px-4 text-sm font-semibold shadow-sm ${tab === 'images' ? 'text-white bg-gray-900 dark:text-zinc-800 dark:bg-white' : 'dark:text-white'}`}
                                                                    onClick={() => setTab('images')}
                                                                >
                                                                    Foto
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`inline-flex items-center gap-x-2 rounded-full py-1.5 px-4 text-sm font-semibold shadow-sm ${tab === 'video' ? 'text-white bg-gray-900 dark:text-zinc-800 dark:bg-white' : 'dark:text-white'}`}
                                                                >
                                                                    Video
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`inline-flex items-center gap-x-2 rounded-full py-1.5 px-4 text-sm font-semibold shadow-sm ${tab === 'map' ? 'text-white bg-gray-900 dark:text-zinc-800 dark:bg-white' : 'dark:text-white'}`}
                                                                    onClick={() => setTab('map')}
                                                                >
                                                                    Harta
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="ml-3 hidden md:flex h-7 items-center">
                                                                <button
                                                                    type="button"
                                                                    className="mx-1 inline-flex items-center gap-x-1.5 rounded-full bg-white dark:bg-zinc-900 px-2.5 py-1.5 text-sm font-semibold text-gray-600 hover:text-white shadow-sm hover:bg-neutral-800"
                                                                >
                                                                    <HeartIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="mx-1 inline-flex items-center gap-x-1.5 rounded-full bg-white dark:bg-zinc-900 px-2.5 py-1.5 text-sm font-semibold text-gray-600 shadow-sm hover:text-white hover:bg-neutral-800"
                                                                >
                                                                    <ShareIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {tab === "images" && <ImageGrid />}
                                                {tab === "map" &&
                                                    <iframe title='map' className='mt-2 h-full' width="100%" style={{ height: "calc(100vh - 35px)" }} src={`https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${mapAddress(propertyData.address, findInObjects(Cities, 'id', 'name', propertyData.city_id))}&z=14&output=embed`}></iframe>
                                                }
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}
