import { useState } from "react";
import { useRegisterMutation } from "../../store/auth/AuthStore";
import { Link, useNavigate } from "react-router-dom";
import EFInput from "../../components/general/EFInput";
import { showNotification } from "../../store/main/MainSlice";
import { useDispatch } from "react-redux";

export default function RegisterPage() {
  //#region costants
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //#endregion

  //#region rtk
  const [register] = useRegisterMutation();
  //#endregion

  //#region states
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    phone: "",
  });
  const [errors, setErrors] = useState<any>();
  //#endregion

  //#region functions
  const handleChange = (e: any, key: string) => {
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const result: any = await register(formData);
    console.log(result);
    if (result.data?.status === 200) {
      navigate("/login");
    }
    if (result.data?.status === 401) {
      dispatch(showNotification({ msg: result.data?.message, visible: true }));
    }
    if (result.error) {
      setErrors(result.error.data.errors);
    } else {
      setErrors(null);
    }
  };
  //#endregion

  return (
    <div className="flex min-h-full flex-1 bg-white dark:bg-zinc-900 dark:text-white">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
              Krijoni nje llogari
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-500">
              Dispononi nje llogari?{" "}
              <Link to="/login" className="font-semibold text-red-600">
                Identifikoju
              </Link>
            </p>
          </div>

          <div className="mt-10">
            <div>
              <form
                onSubmit={handleSubmit}
                action="#"
                method="POST"
                className="space-y-6"
              >
                <EFInput
                  onChange={handleChange}
                  label="Emri i plote"
                  required={true}
                  defaultValue={formData?.username}
                  name="username"
                  validations={errors}
                  hasIcon={true}
                />
                <EFInput
                  onChange={handleChange}
                  label="Telefon"
                  required={true}
                  defaultValue={formData?.phone}
                  name="phone"
                  validations={errors}
                  hasIcon={true}
                />
                <EFInput
                  onChange={handleChange}
                  label="Email"
                  required={true}
                  defaultValue={formData?.email}
                  name="email"
                  validations={errors}
                  hasIcon={true}
                />
                <EFInput
                  onChange={handleChange}
                  label="Fjalekalimi"
                  required={true}
                  defaultValue={formData?.password}
                  name="password"
                  validations={errors}
                  type="password"
                  hasIcon={true}
                />
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-neutral-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-zinc-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-800"
                  >
                    Regjistrohu
                  </button>
                </div>
              </form>
            </div>

            <div className="mt-10">
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 grid grid-cols-2 gap-4">
                <span className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]">
                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                  <span className="text-sm font-semibold leading-6">
                    Twitter
                  </span>
                </span>
                <span className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]">
                  <svg
                    className="h-5 w-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-sm font-semibold leading-6">
                    GitHub
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt=""
        />
      </div>
    </div>
  );
}
