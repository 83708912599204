import React, { ReactNode } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import NotificationAlert from '../../../general/NotificationAlert';

interface LayoutSelectorProps {
    children: ReactNode;
}

const navigation = [
    { name: 'Home', href: '/', current: true },
    { name: 'Automjete', href: '/automjete', current: false },
    { name: 'Lista e automjeteve', href: '/automjete/lista', current: false },
    { name: 'Koncesionare makinash', href: '/automjete/koncesionare', current: false },
    { name: 'Rreth nesh', href: '/automjete/rreth-nesh', current: false },
    { name: 'Na kontaktoni', href: '/automjete/kontakt', current: false },
]

const CarsLayout: React.FC<LayoutSelectorProps> = ({ children }) => {

    return (
        <div>
            <NotificationAlert />
            <Header navigation={navigation} />
            <main className='mt-14'>{children}</main>
            <Footer />
        </div>
    );
};

export default CarsLayout;
