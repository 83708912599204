import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { getCookie, removeCookie, setCookie } from "../../../services/helpers";
import { useLogoutMutation } from "../../../store/auth/AuthStore";
import Broadcrumb from "../../general/Broadcrumb";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function AccountHeader({ handleSidebar }: any) {
  //#region constants
  const broadcrumb = useSelector((state: any) => state.main.broadcrumb);
  const generalData = useSelector((state: any) => state.main.generalData);
  //#endregion

  //#region states
  const [theme, setTheme] = useState(getCookie("theme") === "dark");
  //#endregion

  //#region functions
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    try {
      const result: any = await logout({});
      if (result.data.status === 200) {
        removeCookie("authToken");
        window.location.href = "/";
      }
    } catch (error) {
      console.error("An error occurred while logging in:", error);
    }
  };
  const handleTheme = async () => {
    setTheme(!theme);

    if (theme) {
      document.documentElement.classList.remove("dark");
      setCookie("theme", "light");
    } else {
      document.documentElement.classList.add("dark");
      setCookie("theme", "dark");
    }
  };
  //#endregion

  return (
    <div className="lg:pl-72 fixed w-full z-50">
      <div className="sticky top-0  flex h-16 shrink-0 items-center gap-x-4  bg-white dark:bg-neutral-800 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => handleSidebar(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>

        {/* Separator */}
        <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
        <Broadcrumb pages={broadcrumb} />
        <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <form className="relative  flex-1 hidden sm:flex"></form>
          <div className="relative flex flex-1 items-center sm:hidden">
            <Link to="/">
              <img
                className="h-8 w-auto"
                src="/logo-dark.png"
                alt="Your Company"
              />
            </Link>
          </div>
          <div className="flex items-center gap-x-4 lg:gap-x-6">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div
              className="flex rounded-full text-sm group focus:outline-none cursor-pointer"
              onClick={handleTheme}
            >
              {!theme && (
                <MoonIcon
                  className="block h-6 w-6 dark:text-white hover:text-red-700"
                  aria-hidden="true"
                />
              )}
              {theme && (
                <SunIcon
                  className="block h-6 w-6 dark:text-white hover:text-red-700"
                  aria-hidden="true"
                />
              )}
            </div>

            {/* Separator */}
            <div
              className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10 dark:bg-white"
              aria-hidden="true"
            />

            {/* Profile dropdown */}
            <Menu as="div" className="relative">
              <Menu.Button className="-m-1.5 flex items-center p-1.5">
                <span className="flex lg:items-center">
                  <span
                    className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                    aria-hidden="true"
                  >
                    {generalData.name}
                  </span>
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400 dark:text-white"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2.5 w-max origin-top-right rounded-md bg-white dark:bg-neutral-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        className={classNames(
                          active ? "bg-gray-50 dark:bg-zinc-900" : "",
                          "block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white cursor-pointer"
                        )}
                      >
                        Balanca<strong> ({generalData.balance} pike)</strong>
                      </span>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        href="/account/main/dashboard"
                        className={classNames(
                          active ? "bg-gray-50 dark:bg-zinc-900" : "",
                          "block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white cursor-pointer"
                        )}
                      >
                        Profili
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item key="logout">
                    {({ active }) => (
                      <span
                        onClick={handleLogout}
                        className={classNames(
                          active ? "bg-gray-50 dark:bg-zinc-900" : "",
                          "block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white cursor-pointer"
                        )}
                      >
                        Dil
                      </span>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}
