import { useFetchAgencyQuery } from "../../../store/properties/PropertiesStore"
import { useParams } from 'react-router-dom';
import ContactSeller from '../../../components/general/contacts/ContactSeller'
import { findInObjects, getUrlParams, isNumeric, mapAddress, scrollToDiv } from '../../../services/helpers'
import NotFound from '../../NotFound'
import Loading from '../../../components/general/Loading'
import { Cities } from '../../../services/constants'
import PropertyMainCard from "../../../components/front/cards/PropertyMainCard";
import Pagination from '../../../components/general/Pagination';
import { useState } from "react";
import AgentCard from "../../../components/front/cards/AgentCard";


export default function AgencyDetails() {

    //#region costants
    const { id } = useParams();
    const params = getUrlParams();
    //#endregion

    //#region rtk
    const { data, isFetching } = useFetchAgencyQuery({ id: id, ...params });
    //#endregion

    //#region rtk
    const [page, setPage] = useState(params)
    //#endregion

    if (isFetching && !data?.id) {
        return <Loading />;
    }

    const handlePageClick = async (page: number) => {
        const params = new URLSearchParams(window.location.search);
        params.set('page', page.toString());
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
        setPage(page);
        scrollToDiv('properties', 80);
    };

    if (!isNumeric(id)) {
        return <NotFound />
    }


    const mapUrl = `https://maps.google.com/maps?f=q&source=s_q&hl=en&geocode=&q=${encodeURIComponent(mapAddress(data.address, findInObjects(Cities, 'id', 'name', data.city_id)))}&z=14&output=embed`;

    return (
        <>
            <div className="bg-zinc-200 py-4">
                <div className="mx-auto max-w-screen-2xl px-2 sm:px-0">
                    <div className='flex justify-start pt-8'>
                        <p className="text-sm text-gray-500">Agjensi pronash</p>
                        <p className="text-sm text-gray-500 px-2">|</p>
                        <p className="text-sm text-gray-500"> ({data.properties.total} prona te listuara) </p>
                    </div>
                    <h3 className='text-7xl ui-serif sm:my-8'>{data?.name}</h3>
                    <div className="flex my-8">
                        <button
                            type="button"
                            className="uppercase inline-flex justify-center rounded-md bg-neutral-800 px-2 sm:px-8 py-3 text-sm font-semibold text-white">
                            Dergo mesazh
                        </button>
                        <button
                            type="button"
                            className="ml-4 uppercase inline-flex justify-center rounded-md bg-neutral-800 px-3 py-3 text-sm font-semibold text-white">
                            Shfaq numrin e telefonit
                        </button>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-screen-2xl mt-2">
                <div className="grid grid-cols-12 gap-4 relative px-2">
                    <div className='col-span-12 md:col-span-7 lg:col-span-8 2xl:col-span-8'>
                        <h3 className='text-xl ui-serif mt-2'>Pershkrim</h3>
                        <p className="text-sm text-gray-500"> {data.about}</p>
                        <h3 className='text-xl ui-serif mt-2'>Data e regjistrimit</h3>
                        <p className="text-sm text-gray-500"> {data.created}</p>
                        <h3 className='text-xl ui-serif mt-2'>Adresa</h3>
                        <p className="text-sm text-gray-500 mb-8"> {data.address}</p>

                        <iframe
                            className="mt-"
                            width="100%"
                            height="400"
                            allowFullScreen
                            src={mapUrl}
                            title="Google Maps" />
                        <div className="bg-white z-50">
                            <h3 id="properties" className='text-2xl my-4 ui-serif'>Prona nga {data?.name}</h3>
                            <div className="bg-white">
                                <div className="mx-auto max-w-screen-2xl px-2 py-5 2xl:px-0 sm:py-5">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 gap-y-4 gap-x-4">
                                        {data.properties.data.map((property: any) => (
                                            <PropertyMainCard key={property.id} property={property} />
                                        ))}
                                    </div>
                                    <Pagination
                                        currentPage={data?.properties.current_page ?? 1}
                                        from={data?.properties.from ?? 0}
                                        to={data?.properties.to ?? 0}
                                        total={data?.properties.total ?? 0}
                                        last={data?.properties.last_page ?? 0}
                                        onPageClick={handlePageClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='sticky col-span-12 md:col-span-5 lg:col-span-4 2xl:col-span-4 top-20 h-fit'>
                        <ContactSeller 
                            seller={data} 
                            module="PROPERTIES" 
                            description={`Pershendetje ${data.name}!`}
                            whatsapp={`Pershendetje, `}
                        />
                        <div className="p-4">
                            {data.agents.map((agent: any) => (
                                <AgentCard key={agent.id} agent={agent} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
