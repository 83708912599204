import React, { ReactNode } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import Notification from '../../../general/NotificationAlert';

const navigation = [
    { name: 'Home', href: '/', current: true },
    { name: 'Prona', href: '/prona', current: false },
    { name: 'Automjete', href: '/automjete', current: false },
    { name: 'Punesim', href: '/pune', current: false },
    { name: 'Rreth nesh', href: '/rreth-nesh', current: false },
    { name: 'Na kontaktoni', href: '/kontakt', current: false },
];


interface LayoutSelectorProps {
    children: ReactNode;
}

const FrontLayout: React.FC<LayoutSelectorProps> = ({ children }) => {

    return (
        <div>
            <Notification/>
            <Header navigation={navigation}/>
            <main className='mt-14'>{children}</main>
            <Footer />
        </div>
    );
};

export default FrontLayout;
