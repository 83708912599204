import Slider from '../../../components/front/slider/Slider';
import CarsCarousel from '../../../components/front/carousel/CarsCarousel';
import { useFetchHomeQuery } from '../../../store/cars/CarsStore';
import { SliderItmesType } from '../../../components/types/General';
import BigHorizontalBanner from '../../../components/front/cards/BigHorizontalBanner';
import Loading from '../../../components/general/Loading';

export default function Properties() {

    const mySliderList: SliderItmesType = [
        {
            id: 1,
            name: "Mercedes AMG",
            description: "Shitet mercedes me vaj filtra te sapo bera",
            url: '/slider-1',
            hasButtons: true,
            imageUrl: '/demo/car-1.jpg',
        },
        {
            id: 2,
            name: "Shitet Lambo",
            description: "Shitet Lambo me vaj filtra te sapo bera",
            url: '/slider-2',
            hasButtons: false,
            imageUrl: '/demo/car-2.webp',
        },
        {
            id: 3,
            name: "Shitet BMW",
            description: "Shitet BMW me vaj filtra te sapo bera",
            url: '/slider-2',
            hasButtons: false,
            imageUrl: '/demo/car-3.jpg',
        }
    ]; 

    //#region rtk
    const { data, isFetching } = useFetchHomeQuery({});
    //#endregion

    if(isFetching) return <Loading/>
    return (
        <>
            <div className='properties'>
                <Slider items={mySliderList} url='/autonjete/' btn='Detajet e makines'/>
            </div>
            <div className="mx-auto max-w-screen-2xl">
                <div className="py-10 px-2 2xl:px-0">
                    <CarsCarousel cars={data} />
                </div>
                <div className="pb-10">
                    <BigHorizontalBanner />
                </div>
                <div className="py-10 px-2 2xl:px-0">
                    <CarsCarousel cars={data} />
                </div>
            </div>
        </>
    )
}
