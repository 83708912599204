import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeader from "../../../services/apiHeaders";

export const accountPropertiesApi: any = createApi({
    reducerPath: "account_properties", 
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "properties/account",
        fetchFn: async (...args) => {
            const response = await fetch(...args);
            if (response.status === 401) {
                window.location.href = "/login";
            }
            if (response.status === 403) {
                alert('Ju nuk keni te drejta per te aksesuar kete url!')
                window.history.back();
            }
            return response;
        },
        prepareHeaders(headers) {
            return getHeader(headers);
        },
    }),


    endpoints: (builder) => ({
        fetchPropertyAgencies: builder.query({
            query: (params) => {
                return {
                    url: "agencies",
                    method: "GET",
                    params: params,
                };
            },
        }),
        fetchAgency: builder.query({
            query: (params) => {
                return {
                    url: "agency/profile",
                    method: "GET",
                    params: params
                };
            },
        }),

        saveAgency: builder.mutation({
            query: (body) => {
                const formData = new FormData();
                Object.keys(body).forEach((key) => {
                    formData.append(key, body[key]);
                });

                return {
                    url: '/agency/update',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),

        //Account get property list
        fetchProperties: builder.query({
            query: (params) => {
                return {
                    url: "property-list",
                    method: "GET",
                    params: params,
                };
            },
        }),

        //Account get saved property
        fetchPropertiesSaved: builder.query({
            query: (params) => {
                return {
                    url: "property-saved",
                    method: "GET",
                    params: params,
                };
            },
        }),

        //Account get saved property
        fetchAgencyAgents: builder.query({
            query: (params) => {
                return {
                    url: "agency/agents",
                    method: "GET",
                    params: params,
                };
            },
        }),

        addAgent: builder.mutation({
            query: (body) => {
                return {
                    url: 'agency/add-agent',
                    method: 'POST',
                    body: body,
                };
            },
        }),

        //Create property
        createProperty: builder.mutation({
            query: (body) => {
                const formData = new FormData();

                Object.keys(body).forEach((key) => {
                    if (key === 'image' && Array.isArray(body[key])) {
                        body[key].forEach((image: any, index: any) => {
                            formData.append(`image[${index}]`, image); // Use `image[${index}]` as the key
                        });
                    } else {
                        formData.append(key, body[key]);
                    }
                });

                return {
                    url: '/create-property',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),

        updateProperty: builder.mutation({
            query: (body) => {
                const formData = new FormData();
                Object.keys(body).forEach((key) => {
                    if (key === 'image' && Array.isArray(body[key])) {
                        body[key].forEach((image: any, index: any) => {
                            formData.append(`image[${index}]`, image);
                        });
                    } else {
                        formData.append(key, body[key]);
                    }
                });

                return {
                    url: '/update-property',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),

        updateImagePriority: builder.mutation({
            query: (body) => {

                return {
                    url: '/update-image-priority',
                    method: 'POST',
                    body: body,
                };
            },
        }),

        deleteImage: builder.mutation({
            query: (body) => {

                return {
                    url: '/delete-image',
                    method: 'POST',
                    body: body,
                };
            },
        }),

        //Get data for property update
        fetchPropertyEdit: builder.query({
            query: (params) => {
                return {
                    url: "/property",
                    method: "GET",
                    params: params
                };
            },
        }),

        //Refresh property
        refreshProperty: builder.mutation({
            query: (body) => {
                return {
                    url: '/refresh-property',
                    method: 'POST',
                    body: body,
                };
            },
        }),
        //Delete properties
        deleteProperty: builder.mutation({
            query: (body) => {
                return {
                    url: '/delete-property',
                    method: 'POST',
                    body: body,
                };
            },
        }),

        fetchAgent: builder.query({
            query: (params) => {
                return {
                    url: "agent/profile",
                    method: "GET",
                    params: params
                };
            },
        }),

        saveAgent: builder.mutation({
            query: (body) => {
                const formData = new FormData();
                Object.keys(body).forEach((key) => {
                    formData.append(key, body[key]);
                });

                return {
                    url: '/agent/update',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),
        fetchAgencyContacts: builder.query({
            query: (params) => {
                return {
                    url: "agency/contacts",
                    method: "GET",
                    params: params
                };
            },
        }),
        deleteContact: builder.mutation({
            query: (body) => {
                return {
                    url: '/delete-contact',
                    method: 'POST',
                    body: body,
                };
            },
        }),
        fetchPerson: builder.query({
            query: (params) => {
                return {
                    url: "person/profile",
                    method: "GET",
                    params: params
                };
            },
        }),
        savePerson: builder.mutation({
            query: (body) => {
                const formData = new FormData();
                Object.keys(body).forEach((key) => {
                    formData.append(key, body[key]);
                });

                return {
                    url: '/person/update',
                    method: 'POST',
                    formData: true,
                    headers: {
                        'enctype': 'multipart/form-data',
                    },
                    body: formData,
                };
            },
        }),
    }),
});

export const {
    useFetchPropertyAgenciesQuery,
    useFetchAgencyQuery,
    useAddAgentMutation,
    useFetchAgentQuery,
    useFetchPropertiesQuery,
    useFetchPropertiesSavedQuery,
    useFetchPropertyEditQuery,
    useSaveAgencyMutation,
    useSaveAgentMutation,
    useCreatePropertyMutation,
    useUpdatePropertyMutation,
    useUpdateImagePriorityMutation,
    useDeleteImageMutation,
    useRefreshPropertyMutation,
    useDeletePropertyMutation,
    useFetchAgencyAgentsQuery,
    useFetchAgencyContactsQuery,
    useDeleteContactMutation,
    useFetchPersonQuery,
    useSavePersonMutation
} = accountPropertiesApi;
