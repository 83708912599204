import { useState } from 'react'
import { useFetchPropertyAgentsQuery } from '../../../store/properties/PropertiesStore';
import Pagination from '../../../components/general/Pagination';
import { getUrlParams, scrollToDiv } from '../../../services/helpers';
import Loading from '../../../components/general/Loading';
import { useNavigate } from 'react-router-dom';


export default function Agents() {

    //#region costants
    const params = getUrlParams();
    const navigate = useNavigate();
    //#endregion

    //#region states
    const [page, setPage] = useState(params)
    const [formData] = useState<any>(params);
    //#endregion

    //#region rtk
    const { data, isFetching } = useFetchPropertyAgentsQuery(params);
    //#endregion

    if (isFetching && !data?.meta.total) {
        return <Loading />;
    }

    const handleChange = (e: any, key: string) => {
        let value = e;
        if (key === 'fjaleKyce') {
            value = e.target.value;
            if (value === "") {
                const params = new URLSearchParams(page?.page);
                window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
                setPage(params)
                return;
            }
        }
        setPage({
            ...page,
            [key]: value,
        });
    };

    const handleSearch = async () => {
        const params = new URLSearchParams(page);
        window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
        setPage(params)
    };


    const handlePageClick = async (page: number) => {
        if (typeof window !== "undefined") {
            const params = new URLSearchParams(window.location.search);
            params.set('page', page.toString());
            window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
            setPage(page);
            scrollToDiv('agents', 80);
        }
    };
    //#endregion

    return (
        <div className="bg-white">
            <div className="bg-zinc-200 py-2">
                <div className="mx-auto max-w-screen-2xl px-2 sm:px-0">
                    <div className='flex justify-start pt-2'>
                        <p className="text-sm text-gray-500">Prona</p>
                        <p className="text-sm text-gray-500 px-2">|</p>
                        <p className="text-sm text-gray-500">  Agjente </p>
                    </div>
                    <h3 className='text-5xl ui-serif sm:my-4'>Lista e agjenteve</h3>
                    <div className="flex my-8">
                        <div className="">
                            <input
                                autoComplete='off'
                                onChange={(e) => handleChange(e, 'fjaleKyce')}
                                placeholder='Krko sipas emrit'
                                type="text"
                                name="fjaleKyce"
                                defaultValue={formData?.fjaleKyce}
                                className=" w-full px-3 py-2 border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-gray-500 invalid:border-red-500 invalid:text-red-600"
                            />
                        </div>
                        <button
                            onClick={handleSearch}
                            type="button"
                            className="ml-4 inline-flex justify-center p-2 rounded-md bg-neutral-800 px-3  text-sm font-semibold text-white">
                            Kerko
                        </button>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-screen-2xl px-2 sm:px-0 py-5 2xl:px-0 sm:py-5" id='agents'>
            <ul className="mx-auto md:mt-8 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4">
                    {data?.data.map((agent: any) => (
                         <li key={agent.id} className="text-center border">
                         <img
                           alt=""
                           src={agent.image}
                           className="mx-auto w-full object-contain bg-gray-100 md:p-5 md:aspect-square"
                           onClick={() => navigate(`/prona/agjent/${agent.id}`)}
                         />
                         <h3
                           className="mt-6 text-base/7 font-semibold tracking-tight text-gray-900"
                           onClick={() => navigate(`/prona/agjent/${agent.id}`)}
                         >
                           {agent.name}
                         </h3>
                         <p className="text-sm/6 text-gray-600">
                           Agjensi pronash ({agent.properties_count} listime)
                         </p>
                         <p className="text-sm/6 text-gray-600 px-5 line-clamp-2">
                           {agent.about}
                         </p>
                         <ul className="mt-3 flex justify-center gap-x-6 pb-5">
                           <li>
                             <a
                               href="linkedin.com"
                               className="text-gray-400 hover:text-gray-500"
                             >
                               <span className="sr-only">X</span>
                               <svg
                                 fill="currentColor"
                                 viewBox="0 0 20 20"
                                 aria-hidden="true"
                                 className="size-5"
                               >
                                 <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                               </svg>
                             </a>
                           </li>
                           <li>
                             <a
                               href={agent.linkedinUrl}
                               className="text-gray-400 hover:text-gray-500"
                             >
                               <span className="sr-only">LinkedIn</span>
                               <svg
                                 fill="currentColor"
                                 viewBox="0 0 20 20"
                                 aria-hidden="true"
                                 className="size-5"
                               >
                                 <path
                                   d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                   clipRule="evenodd"
                                   fillRule="evenodd"
                                 />
                               </svg>
                             </a>
                           </li>
                         </ul>
                       </li>
                        // <div key={agent.id} className="flex flex-col gap-5 sm:flex-row border my-4 mx-2 rounded-2xl cursor-pointer" onClick={() => navigate(`/prona/agjent/${agent.id}`)}>
                        //     <img className="sm:aspect-[5/5] sm:w-40 flex-none object-contain p-16 sm:p-2 bg-gray-100" src={agent.image} alt="" />
                        //     <div className="max-w-xl flex-auto mr-4 p-2 sm:p-0">
                        //         <h3 className="text-lg font-semibold leading-8 tracking-tight text-gray-900">{agent.name}</h3>
                        //         <p className="text-base leading-7 text-gray-600">Agjent ({agent.properties_count} prona te listuara)</p>
                        //         <p className="mt-2 text-sm text-base leading-7 text-gray-600 line-clamp-2">
                        //             {agent.desc}
                        //         </p>
                        //         <ul role="list" className="mt-2 flex gap-x-6">
                        //             <li>
                        //                 <a href={agent.twitterUrl} className="text-gray-400 hover:text-gray-500">
                        //                     <span className="sr-only">Twitter</span>
                        //                     <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        //                         <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        //                     </svg>
                        //                 </a>
                        //             </li>
                        //             <li>
                        //                 <a href={agent.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                        //                     <span className="sr-only">LinkedIn</span>
                        //                     <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        //                         <path
                        //                             fillRule="evenodd"
                        //                             d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        //                             clipRule="evenodd"
                        //                         />
                        //                     </svg>
                        //                 </a>
                        //             </li>
                        //             <li>
                        //                 <a href={agent.twitterUrl} className="text-gray-400 hover:text-gray-500">
                        //                     <span className="sr-only">Twitter</span>
                        //                     <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        //                         <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                        //                     </svg>
                        //                 </a>
                        //             </li>
                        //             <li>
                        //                 <a href={agent.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                        //                     <span className="sr-only">LinkedIn</span>
                        //                     <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        //                         <path
                        //                             fillRule="evenodd"
                        //                             d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                        //                             clipRule="evenodd"
                        //                         />
                        //                     </svg>
                        //                 </a>
                        //             </li>
                        //         </ul>
                        //     </div>
                        // </div>
                    ))}
                </ul>
                <Pagination
                    currentPage={data?.meta.current_page ?? 1}
                    from={data?.meta.from ?? 0}
                    to={data?.meta.to ?? 0}
                    total={data?.meta.total ?? 0}
                    last={data?.meta.last_page ?? 0}
                    onPageClick={handlePageClick}
                />
            </div>

        </div>
    )
}
