import Slider from '../../../components/front/slider/Slider';
import PropertiesCarousel from '../../../components/front/carousel/PropertiesCarousel';
import { useFetchPropertiesQuery } from '../../../store/properties/PropertiesStore';
import { SliderItmesType } from '../../../components/types/General';
import BigHorizontalBanner from '../../../components/front/cards/BigHorizontalBanner';
import Loading from '../../../components/general/Loading';

export default function Properties() {

    const mySliderList: SliderItmesType = [
        {
            id: 1,
            name: "Shitet apartament 2+1 ne Tirane",
            description: "Shitet apartament me hipoteke ne rrugen Mine Peza.",
            url: '/slider-1',
            hasButtons: true,
            imageUrl: '/demo/property_img_6.jpg',
        },
        {
            id: 2,
            name: "Shitet apartament 2+1 ne Durres",
            description: "Shitet apartament me hipoteke ne durres ne zonene plazhit.",
            url: '/slider-2',
            hasButtons: false,
            imageUrl: '/demo/property_img_1.jpg',
        },
        {
            id: 3,
            name: "Shitet apartament 2+1 ne Tirane",
            description: "Shitet apartament me hipoteke ne rrugen Mine Peza.",
            url: '/slider-1',
            hasButtons: true,
            imageUrl: '/demo/property_img_6.jpg',
        },
        {
            id: 4,
            name: "Shitet apartament 2+1 ne Durres",
            description: "Shitet apartament me hipoteke ne durres ne zonene plazhit.",
            url: '/slider-2',
            hasButtons: false,
            imageUrl: '/demo/property_img_1.jpg',
        },
    ];

    //#region rtk
    const { data, isFetching } = useFetchPropertiesQuery({});
    //#endregion

    if(isFetching) return <Loading/>
    return (
        <>
            <div className='properties'>
                <Slider items={mySliderList} />
            </div>
            <div className="mx-auto max-w-screen-2xl">
                <div className="py-10 px-2 2xl:px-0">
                    <PropertiesCarousel properties={data?.data} />
                </div>
                {/* <div className="pb-10">
                    <PartnersImg title="Biznese qe kane besuar te bashkepunojne me ne"/>
                </div> */}
                <div className="pb-10">
                    <BigHorizontalBanner />
                </div>
                <div className="py-10 px-2 2xl:px-0">
                    <PropertiesCarousel properties={data?.data} />
                </div>
            </div>
        </>
    )
}
