
import { getCookie } from "./helpers";

function getHeader(headers: any) {
    let token = getCookie("authToken");
    // let language = getCookie({ key: "language" });
    // if (isNotNullOrUndefined(language)) {
    //   language = "en";
    // }


    if (token) {
        headers.set("Authorization", `Bearer ${token}`);
    }
    // headers.set("Language", language);
    return headers;
}
export default getHeader;