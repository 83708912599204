import { configureStore } from "@reduxjs/toolkit";
import { propertiesApi } from "./properties/PropertiesStore";
import { carsApi } from "./cars/CarsStore";
import { mainApi } from "./main/MainStore";
import { accountPropertiesApi } from "./account/properties/accountPropertiesStore";
import { accountCarsApi } from "./account/cars/accountCarsStore";


import { PropertiesSlice } from "./properties/propertiesReducer";
import { MainSlice } from "./main/MainSlice";
import { authApi } from "./auth/AuthStore";

export const store = configureStore({ 
  reducer: {
    properties: PropertiesSlice.reducer,
    main: MainSlice.reducer,
    [propertiesApi.reducerPath]: propertiesApi.reducer,
    [carsApi.reducerPath]: carsApi.reducer,
    [mainApi.reducerPath]: mainApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [accountPropertiesApi.reducerPath]: accountPropertiesApi.reducer,
    [accountCarsApi.reducerPath]: accountCarsApi.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
        mainApi.middleware,
        propertiesApi.middleware,
        carsApi.middleware,
        authApi.middleware,
        accountPropertiesApi.middleware,
        accountCarsApi.middleware,

      ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;