import { HomeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const Broadcrumb = ({ pages }: any) => {
    
    return (
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
                <li>
                    <div>
                        <Link to="#" className="text-gray-400 hover:text-gray-500 dark:text-white">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                        </Link>
                    </div>
                </li>
                {pages.map((page:any) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            <svg
                                className="h-5 w-5 flex-shrink-0 text-gray-300 dark:text-white"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                            >
                                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                            </svg>
                            <Link
                                to={page.href}
                                className={`ml-4 text-sm font-medium hover:text-gray-700 dark:text-white ${page.current ? 'text-gray-800 dark:text-gray-500' : 'text-gray-500'}`}
                                aria-current={page.current ? 'page' : undefined}
                            >
                                {page.name}
                            </Link>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
};

export default Broadcrumb;