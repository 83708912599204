import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface INotification {
  visible: boolean;
  msg?: string;
}
export interface IMain {
  data: any;
  notification: INotification;
  generalData: {},
  broadcrumb: []
}

const initialState: IMain = {
  data: undefined,
  notification: {
    visible: false,
    msg: "",
  },
  generalData: {},
  broadcrumb: []
};

// creating action-reducer slice
export const MainSlice = createSlice({
  name: "main_r",
  initialState,
  reducers: {
    showNotification: (state, action: PayloadAction<INotification>) => {
      state.notification = action.payload;
    },
    generalData: (state, action: PayloadAction<INotification>) => {
      state.generalData = action.payload;
    },
    broadcrumb: (state, action: PayloadAction<any>) => {
      state.broadcrumb = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showNotification, generalData, broadcrumb } = MainSlice.actions;

export default MainSlice.reducer;