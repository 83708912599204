import { useEffect, useState, useRef } from 'react';
import { getUrlParams, scrollToDiv, truncateString } from '../../../services/helpers';
import Pagination from '../../../components/general/Pagination';
import { broadcrumb, showNotification } from '../../../store/main/MainSlice';
import { useDispatch } from 'react-redux';
import Loading from '../../../components/general/Loading';
import { useFetchAgencyContactsQuery, useDeleteContactMutation } from '../../../store/account/properties/accountPropertiesStore';
import { useCalculateHeight } from '../../../hooks/calculateHeight';
import EFInput from '../../../components/general/EFInput';

const pages = [
  { name: 'Prona', href: '/account/properties/dashboard', current: false },
  { name: 'Agency', href: '/account/properties/agency/profile', current: false },
  { name: 'Contacts', href: '#', current: true },
]

export default function Contacts() {

  const params = getUrlParams();
  const dispatch = useDispatch();
  const height = useCalculateHeight(200);

  //#region rtk
  const { data, isFetching, refetch } = useFetchAgencyContactsQuery(params);
  const [deleteContact] = useDeleteContactMutation();

  //#endregion

  //#region refs
  const refCheckbox = useRef<any>();
  const refSearch = useRef<any>();
  //#endregion

  //#region states
  const [page, setPage] = useState(params)
  const [checked, setChecked] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any>([])
  //#endregion

  //#region functions
  const handlePageClick = async (page: number) => {
    const params = new URLSearchParams(window.location.search);
    params.set('page', page.toString());
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    setPage(page);
    scrollToDiv('properties', 80);
  };

  const handleDeleteContact = async () => {
    const response: any = await deleteContact({ ids: selectedRows });
    if (response.data?.status) {
      dispatch(showNotification({ msg: response.data.message, visible: true }));
      setSelectedRows([]);
      refetch();
    }
  };

  function toggleAll() {
    setSelectedRows(checked ? [] : data?.data.map((item: any) => item.id))
    setChecked(!checked)
  }
  function handleSelect(e: any, agent: any) {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, agent.id]);
    } else {
      setSelectedRows(selectedRows.filter((row: any) => row !== agent.id))
    }
  }

  const handleSearch = async () => {
    const params = new URLSearchParams(page);
    window.history.replaceState({}, '', `${window.location.pathname}?${params.toString()}`);
    setPage(params)
  };

  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  //#endregion

  //#region useEfffect
  useEffect(() => {
    dispatch(broadcrumb(pages));
  }, []);
  //#endregion

  if (isFetching && !data?.meta) {
    return <Loading />;
  }

  return (
    <>
      <div id='properties'>
        <div className="sm:flex sm:items-center mb-2">
          <div className="sm:flex-auto">
            {selectedRows.length > 0 && (
              <div className="left-14 top-0 flex items-center space-x-3 bg-white sm:left-12">
                {selectedRows.length > 0 && <button
                  onClick={handleDeleteContact}
                  type="button"
                  className="mb-2 inline-flex items-center border rounded bg-red-600 px-2 py-1 text-sm font-semibold text-white hover:bg-red-700 h-7"
                >
                  Fshi te selektuarat
                </button>}
              </div>
            )}
          </div>
          <div className="flex mt-4 sm:ml-16 sm:mt-0 sm:flex-none" onKeyDown={handleKeyDown}>
            <EFInput onChange={(e) => setPage({
              ...page,
              ['fjaleKyce']: e.target.value,
            })} refValue={refSearch} required={true} name='code' placeholder='Kerko' defaultValue={params.fjaleKyce} />
            <button
              onClick={handleSearch}
              style={{ height: "38px" }}
              type="button"
              className="block ml-2 rounded-md bg-neutral-800 px-3 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Kerko
            </button>
          </div>
        </div>
        <div className="overflow-auto" style={{ height: height }}>
          <table className="min-w-full divide-y divide-neutral-300">
            <thead className="bg-neutral-300 sticky top-0 z-10">
              <tr>
                <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
                  <input
                    type="checkbox"
                    className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                    ref={refCheckbox}
                    checked={checked}
                    onChange={toggleAll}
                  />
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Emri
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Telefon
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Email
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Kontenti
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Data
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-neutral-300 bg-white">
              {data?.data.map((contact: any) => (
                <tr key={contact.id} className='even:bg-gray-100'>
                  <td className="relative px-7 sm:w-12 sm:px-6">
                    {selectedRows.includes(contact.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-red-600" />
                    )}
                    <input
                      type="checkbox"
                      className="absolute custom-checkbox left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                      value={contact.email}
                      checked={selectedRows.includes(contact.id)}
                      onChange={(e) => handleSelect(e, contact)}
                    />
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900">{contact.name}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900">{contact.phone}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900">{contact.email}</div>
                  </td>
                  <td className="whitespace-normal px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900 max-w-md break-words overflow-auto" dangerouslySetInnerHTML={{ __html: contact?.description }} />
                  </td>
                  <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <div className="text-gray-900">{contact.created_at}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination
        currentPage={data?.current_page ?? 1}
        from={data?.from ?? 0}
        to={data?.to ?? 0}
        total={data?.total ?? 0}
        last={data?.last_page ?? 0}
        onPageClick={handlePageClick}
      />
    </>
  )
}
