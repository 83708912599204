import {
  isLogedIn,
  isNotNullOrUndefined,
  price,
  removeCookie,
} from "../../../../src/services/helpers";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import "swiper/css";
import "swiper/css/pagination";
import "../carousel/styles.css";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import { findInObjects } from "../../../../src/services/helpers";
import {
  Cities,
  CarFuels,
  CarMilages,
  CarCambios,
} from "../../../services/constants";
import { useSavePropertyMutation } from "../../../store/properties/PropertiesStore";
import { useDispatch } from "react-redux";
import { showNotification } from "../../../store/main/MainSlice";
import PropertyContactModal from "../../general/contacts/PropertyContactModal";

export default function CarMainCard(props: any) {
  //#region constants
  const dispatch = useDispatch();
  const [saveProperty] = useSavePropertyMutation();
  const [car, setCar] = useState(props.car);
  //#endregion

  //#region refs
  const refContactModal = useRef<any>();
  //#endregion

  //#region functions
  const handleSaveProperty = async () => {
    try {
      if (isLogedIn()) {
        const result: any = await saveProperty({ ids: [car.id] });
        if (result?.data?.status) {
          const updatedProperty = { ...car };
          updatedProperty.liked = !updatedProperty.liked;
          setCar(updatedProperty);
          dispatch(
            showNotification({ msg: result.data.message, visible: true })
          );
        }
        if (result?.error?.status === 401) {
          dispatch(
            showNotification({ msg: "Please login first!", visible: true })
          );
          removeCookie("authToken");
          window.location.reload();
        }
      } else {
        dispatch(
          showNotification({ msg: "Please login first!", visible: true })
        );
      }
    } catch (error) { }
  };
  const handleContactBtn = () => {
    if (refContactModal.current) {
      if (car?.agent)
        return refContactModal.current?.handleContactPopup(
          car.agent.user_id,
          "PROPERTIES"
        );
      if (car?.agency)
        return refContactModal.current?.handleContactPopup(
          car.agency.user_id,
          "PROPERTIES"
        );
      if (car?.person)
        return refContactModal.current?.handleContactPopup(
          car.person.user_id,
          "PROPERTIES"
        );
    }
  };
  //#endregion

  return (
    <>
      <PropertyContactModal
        forwardedRef={refContactModal}
        description={`Pershendetje interesohem ne lidhje me <a href="/automjete/${car.id}" class="ck-link_selected"><strong>${car.name}</strong></a>!`}
      />
      <div className="group relative flex flex-col overflow-hidden border border-gray-200 dark:border-neutral-800 bg-white dark:bg-transparent dark:text-white">
        <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none relative">
          <Swiper
            slidesPerView={1}
            modules={[Navigation]}
            loop={false}
            className="swiper-card-img-swiper"
            navigation={{
              nextEl: ".swiper-card-img-next",
              prevEl: ".swiper-card-img-prev",
            }}
          >
            <div className="swiper-card-img">
              <div className="swiper-card-img-prev swiper-button-prevs"></div>
              <div className="swiper-card-img-next swiper-button-nexts"></div>
            </div>
            {car?.images_urls
              ?.slice(0, 5)
              .map((image: any, index: any) => (
                <SwiperSlide key={index}>
                  {index === 4 ? (
                    <div>
                      <img
                        src={image.url}
                        alt={image.name}
                        className="h-64 sm:h-80 w-full object-cover object-center"
                      />
                      <div className="absolute w-full h-80 text-center z-10 pt-36 top-0">
                        <Link
                          to="#"
                          className="text-white rounded-xl bg-white px-3 py-1 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-neutral-800 hover:text-white"
                        >
                          Shiko {car.images_urls.length} fotot
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <img
                      src={image.url}
                      alt={image.name}
                      className="h-64 sm:h-80 w-full object-cover object-center"
                    />
                  )}
                </SwiperSlide>
              ))}
          </Swiper>
          <div className="absolute right-3 top-3 z-10 flex">
            <HeartIcon
              title={car.liked ? "E preferuar" : "Shto tek te preferuarat"}
              className={`h-8 w-8 text-white ${car.liked ? "fill-white" : ""
                }`}
              onClick={handleSaveProperty}
            />
          </div>
          <div className="absolute left-3 top-3">
            <div className="flex">
              <Link
                to="#"
                className="text-white z-10 rounded-md bg-white px-3 py-1 text-sm font-semibold text-zinc-800 shadow-sm hover:bg-neutral-800 hover:text-white"
              >
                {car.status === 1 ? "Shitet" : "Qera"}
              </Link>
            </div>
          </div>
          <div className="absolute bottom-0 right-0 bg-white/50 z-10 px-3 py-1">
            <img
              width={70}
              src={car.agency.image}
              alt="test"
              className="h-10 object-contain object-left rounded-md"
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col space-y-1 p-4 bg-gray-50 dark:bg-transparent">
          <div className="flex justify-between">
            <div>{price(car.price)}</div>
            <div className="flex cursor-pointer" onClick={handleContactBtn}>
              <ChatBubbleBottomCenterTextIcon
                className="h-5 w-5"
                aria-hidden="true"
              />
            </div>
          </div>
          <h3 className="truncate text-md font-medium text-gray-900 dark:text-white hover:text-red-700 dark:hover:text-red-600">
            <Link to={`/prona/${car.id}`}>{car.name}</Link>
          </h3>
          {/* <p className="text-xs mt-0 dark:text-gray-400 flex">
            <MapPinIcon className="h-4 pr-1" />
            {car.address},{" "}
            {findInObjects(Cities, "id", "name", car.city)}
          </p> */}
          <p
            className="text-sm text-gray-500 dark:text-gray-400 line-clamp-2"
            dangerouslySetInnerHTML={{ __html: car?.description }}
          ></p>
          <div className="flex justify-start text-gray-800 dark:text-white">
            <p className="text-sm">{car.make.name} {car.model.name} {car.year}</p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">{findInObjects(CarFuels, 'id', 'name', car.fuel_id)}</p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">{findInObjects(CarMilages, 'id', 'name', car.milage)}</p>
            <p className="text-sm italic px-1"> | </p>
            <p className="text-sm">{findInObjects(CarCambios, 'id', 'name', car.cambio_id)}</p>
          </div>
        </div>
        {/* <hr className="dark:border-neutral-800" />
        <div className="flex justify-between p-4 bg-gray-100 dark:bg-transparent">
          {car.agent && !car?.agent?.image && (
            <p className="text-sm text-gray-500 my-auto pr-2">
              {car?.agent?.name}
            </p>
          )}
          {car.agency && !car?.agency?.image && (
            <p className="text-sm text-gray-500 my-auto pr-2">
              {car?.agent?.name}
            </p>
          )}
          {car.person && !car?.person?.image && (
            <p className="text-sm text-gray-500 my-auto pr-2">
              {car?.person?.name}
            </p>
          )}
          {car?.person?.image && (
            <div className="text-right float-right right-0">
              <div className="flex">
                <img
                  src={car.person.image}
                  alt="test"
                  className="h-10 object-contain object-left rounded-md"
                />
                <p className="text-sm text-gray-500 my-auto pl-2">
                  {car?.person?.name}
                </p>
              </div>
            </div>
          )}
          {car?.agency?.image && (
            <div className="flex justify-end">
              <img
                src={car.agency.image}
                alt="test"
                className="h-10 object-contain object-left rounded dark:invert"
              />
            </div>
          )}
          {car?.agent?.image && (
            <div className="flex justify-end">
              <p className="text-sm text-gray-500 my-auto pr-2">
                {car.agent?.name}
              </p>
              <img
                alt="Agent profile img"
                src={car.agent?.image}
                className="h-10 object-contain object-left rounded-md"
              />
            </div>
          )}
        </div> */}
      </div>
    </>
  );
}
