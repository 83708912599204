import React from "react";

type PaginationClientProps = {
  from: number;
  to: number;
  last: number;
  currentPage: number;
  total: number;
  onPageClick: (page: number) => void;
};

const Pagination: React.FC<PaginationClientProps> = ({
  currentPage,
  from,
  to,
  last,
  total,
  onPageClick,
}) => {
  // Calculate page numbers based on current page and total pages
  const pageNumbers = [];
  const maxVisiblePages = 5; // Number of pages to show (adjust as needed)

  if (last <= maxVisiblePages) {
    for (let i = 1; i <= last; i++) {
      pageNumbers.push(i);
    }
  } else {
    if (currentPage <= Math.ceil(maxVisiblePages / 2)) {
      for (let i = 1; i <= maxVisiblePages; i++) {
        pageNumbers.push(i);
      }
    } else if (currentPage >= last - Math.floor(maxVisiblePages / 2)) {
      for (let i = last - maxVisiblePages + 1; i <= last; i++) {
        pageNumbers.push(i);
      }
    } else {
      const halfVisible = Math.floor(maxVisiblePages / 2);
      for (
        let i = currentPage - halfVisible;
        i <= currentPage + halfVisible;
        i++
      ) {
        pageNumbers.push(i);
      }
    }
  }

  return (
    <div className="py-3 mt-2">
      <div className="flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="hidden sm:flex">
          <p className="text-sm text-gray-700 dark:text-white">
            Shfaqur <span className="font-medium">{from}</span> deri{" "}
            <span className="font-medium">{to}</span> nga{" "}
            <span className="font-medium">{total}</span> rezultate
          </p>
        </div>
        <div className="float-right">
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <span
              aria-disabled={currentPage === 1}
              className={`relative cursor-pointer inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover-bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                currentPage === 1 ? "pointer-events-none opacity-50" : ""
              }`}
              onClick={(e) => {
                if (currentPage > 1) {
                  e.preventDefault();
                  onPageClick(1);
                }
              }}
            >
              <span className="text-sm font-semibold">Fillim</span>
            </span>
            {pageNumbers.map((page) => (
              <span
                key={page}
                className={`relative cursor-pointer inline-flex items-center ${
                  page === currentPage
                    ? "bg-neutral-800 text-white dark:bg-white dark:text-zinc-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    : "text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover-bg-gray-50 focus:outline-offset-0"
                } px-4 py-2 text-sm font-semibold`}
                onClick={(e) => {
                  e.preventDefault();
                  onPageClick(page);
                }}
              >
                {page}
              </span>
            ))}
            <span
              aria-disabled={currentPage === last}
              className={`relative cursor-pointer inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-neutral-700 hover-bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                currentPage === last ? "pointer-events-none opacity-50" : ""
              }`}
              onClick={(e) => {
                if (currentPage < last) {
                  e.preventDefault();
                  onPageClick(last);
                }
              }}
            >
              <span className="text-sm font-semibold">Fund</span>
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
