import { isNotNullOrUndefined } from "../../services/helpers";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function CkEdit(
    {
        label,
        name,
        defaultValue,
        onChange,
        validations,
        required,
        className
    }
        :
        {
            label?: string;
            name: string;
            onChange: any;
            defaultValue?: any;
            validations?: any;
            required?: boolean;
            className?: string;
        }
) {

    return (
        <div className={className}>
            {isNotNullOrUndefined(label) &&
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    {label} <span className="text-red-600">{required ? "*" : ""}</span>
                </label>
            }
            <CKEditor
                editor={ClassicEditor as any}
                config={{
                    toolbar: [
                        'undo',
                        'redo',
                        '|',
                        'bold',
                        'italic',
                        'heading',
                        '|',
                        'link',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'blockQuote',
                        '|',
                        'indent',
                        'outdent',
                        '|',
                        'insertTable'
                    ]
                }}
                data={defaultValue}
                onChange={onChange}
            />
            {validations && name && validations[name] && (
                <p className="mt-2 text-sm text-red-600" id="email-error">
                    {validations[name]}
                </p>
            )}
        </div>
    );
}
