import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getHeader from "../../../src/services/apiHeaders";

// API endpoint for fetching data
const url = process.env.REACT_APP_API_BASE_URL;

export const mainApi = createApi({
    reducerPath: "main_r",
    baseQuery: fetchBaseQuery({
        baseUrl: url + "main",
        fetchFn: async (...args) => {
            return fetch(...args);
        },
        prepareHeaders(headers) {
            return getHeader(headers);
        },
    }),

    endpoints: (builder) => ({
        fetchGeneralData: builder.query({
            query: () => {
                return {
                    url: "get-general-data",
                    method: "GET",
                };
            },
        }),
        fetchMain: builder.query({
            query: () => {
                return {
                    url: "",
                    method: "GET",
                };
            },
        }),
        contactSeller: builder.mutation({
            query: (body) => ({
                url: '/contact-seller',
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useFetchMainQuery, useFetchGeneralDataQuery, useContactSellerMutation } = mainApi;
